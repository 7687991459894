import { useState, useEffect } from 'react'
// import PricingContainer from './PricingContainer'
// import MainContent from './MainContent'
import ImageCarousel from './ImageCarousel'

import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
// import Testimonials from '../../components/Testimonials/Testimonials'
import WhyJoin from '../../components/WhyJoin/WhyJoin'
import NewPricingCard from './NewPricingCard'

const ContainerBox = styled(Box)(() => ({}))

const Landing = ({ userRole }) => {
  return (
    <ContainerBox>
      <ImageCarousel />
      <WhyJoin />
      <Grid container>
        <Grid item xs={12} mb={3}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} id='pricing'>
            <Typography
              variant='body1'
              color='#6F6C90'
              mt={1}
              mb={1}
              borderRadius={8}
              px={2}
              bgcolor='#F9F5FF'
            >
              Pricing plans
            </Typography>
          </Box>
          <Typography variant='h3' color='initial' textAlign={'center'} mb={1}>
            Plans for all sizes
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant='body2'
              textAlign={'center'}
              sx={{ width: { xs: '100%', md: '60%' }, mb: 2 }}
            >
              Simple, transparent pricing that grows with you. Focus of development creds easily and
              securely quick copy. Work by yourself Coordinate as a Easy to manage.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          component={Container}
          spacing={2}
          xs={12}
          sx={{
            backgroundImage: "url('/Background pattern.png')",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <NewPricingCard userRole={userRole} />

          <Grid
            id='pricingcard'
            item
            xs={12}
            sm={6}
            md={4}
            justifyContent='center'
            alignItems={'baseline'}
            display={'flex'}
          >
            <Box
              sx={{
                '& div>div>.MuiTypography-root.MuiTypography-body2': {
                  textAlign: 'start',
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '570px',
                bgcolor: (theme) => theme.palette.pricing.background,
                color: (theme) => theme.palette.pricing.headerText,
                width: { xs: '100%', md: '82%' },
                textAlign: 'center',
                py: 4,
                pl: 5,
                pr: 2,
                borderRadius: '16px',
                boxShadow: (theme) => theme.palette.pricing.shadow,
                border: (theme) => `1px solid ${theme.palette.pricing.border}`,
                '& .MuiTypography-root': {
                  color: (theme) => theme.palette.pricing.headerText,
                },
                '& .plan-title': {
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? theme.palette.primary.light : '#4A3AFF',
                  fontWeight: '500',
                  py: 1,
                },
                '& .plan-feature': {
                  color: (theme) => theme.palette.pricing.featureText,
                },
                '& .MuiButton-root': {
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
                  '&:hover': {
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.primary.dark : undefined,
                  },
                },
              }}
            >
              <div>
                <img src='/icons/Featured icon-1.png' alt='' width={40} height={40} />
                <Typography variant='body1' className='plan-title'>
                  Enterprise plan
                </Typography>
                <Typography variant='body2' py={1}>
                  Billed annually.
                </Typography>
                <Box sx={{ pt: 4, mb: 2, display: 'flex' }}>
                  <img src='/icons/Check icon.png' alt='' width={24} height={24} />
                  <Typography variant='body2' pl={2} className='plan-feature'>
                    Audit log and data history
                  </Typography>
                </Box>
                <Box sx={{ mb: 2, display: 'flex' }}>
                  <img src='/icons/Check icon.png' alt='' width={24} height={24} />
                  <Typography variant='body2' pl={2} className='plan-feature'>
                    unlimited projects
                  </Typography>
                </Box>
                <Box sx={{ mb: 2, display: 'flex' }}>
                  <img src='/icons/Check icon.png' alt='' width={24} height={24} />
                  <Typography variant='body2' pl={2} className='plan-feature'>
                    Unlimited individual users
                  </Typography>
                </Box>
                <Box sx={{ mb: 2, display: 'flex' }}>
                  <img src='/icons/Check icon.png' alt='' width={24} height={24} />
                  <Typography variant='body2' pl={2} className='plan-feature'>
                    Unlimited individual data
                  </Typography>
                </Box>
                <Box sx={{ mb: 5, display: 'flex' }}>
                  <img src='/icons/Check icon.png' alt='' width={24} height={24} />
                  <Typography variant='body2' pl={2} className='plan-feature'>
                    Personalised+priotity service
                  </Typography>
                </Box>
              </div>
              <Button
                variant='contained'
                sx={{
                  py: '14px!important',
                  fontWeight: 700,
                  fontSize: 16,
                  mr: 3,
                }}
              >
                Contact Sales
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ContainerBox>
  )
}

export default Landing
