import { styled } from '@mui/material/styles'
import { Box, Typography, Avatar, Chip, Tooltip, Button } from '@mui/material'
import OtherTeamsInfoModal from './OtherTeamsInfoModal'
import { useTeamOwnersContext } from '../../../hooks/useTeamOwnersContext'
import { useEffect } from 'react'
import { Unpublished, Verified } from '@mui/icons-material'

const UerInfoCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'cardWrapped',
})(({ theme, cardWrapped }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background:
    theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.light,
  position: 'relative',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0px 3px 11px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.15)'
      : '0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)',
  borderRadius: cardWrapped ? '3px 3px 0px 0px' : '3px 0px 0px 3px',
  padding: '2rem 2rem',
  minWidth: '300px',
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1.2rem',
  },
  '&>*': {
    marginBottom: '10px',
  },
  '&>.avtarBox > .MuiAvatar-root': {
    textTransform: 'uppercase',
    border: `3px solid ${theme.palette.mode === 'dark' ? theme.palette.primary.main : '#FFFFFF'}`,
    fontSize: '3rem',
    width: 150,
    height: 150,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 100,
    },
  },
  '&>.infoBox': {
    textAlign: 'center',
    '&>.MuiTypography-root': {
      maxWidth: '340px',
      wordBreak: 'break-all',
      padding: '5px 0px',
      '&.heading': {
        fontWeight: '700',
        color: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.2rem',
        letterSpacing: '1px',
      },
      '&.content': {
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
  '& .MuiChip-root': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.08)',
    color:
      theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : 'rgba(0, 0, 0, 0.87)',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.23)',
  },
  '& .MuiButton-outlined': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.87)',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.87)',
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(252, 179, 37, 0.08)' : 'rgba(0, 0, 0, 0.04)',
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.87)',
  },
}))

const UserInfo = ({ user, cardWrapped, thumbnailUrl, displayName, userName, userRole }) => {
  // user.emailVerified = false;
  console.log(user.emailVerified)
  const { teamOwners, dispatch } = useTeamOwnersContext()

  useEffect(() => {
    dispatch({ type: 'removeIsFetched' })
  }, [dispatch])

  return (
    <UerInfoCard cardWrapped={cardWrapped}>
      <OtherTeamsInfoModal teamOwners={teamOwners} />
      <Box className='avtarBox'>
        <Avatar alt='profile' src={thumbnailUrl ? thumbnailUrl : ''} />
      </Box>
      <Tooltip title='Role'>
        <Chip
          label={userRole ? 'Team Manager' : teamOwners.length > 0 ? 'team Member' : 'solo'}
          variant='outlined'
          color='secondary'
          size='small'
          sx={{ textTransform: 'capitalize', mt: 1, fontWeight: 'bold' }}
        />
      </Tooltip>
      <Box className='infoBox'>
        <Typography variant='h4' className='heading' textTransform={'capitalize'}>
          {userName || 'Not available!'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Typography className='content'>{user?.email}</Typography>
          {user.emailVerified === true ? <Verified /> : <Unpublished />}
        </Box>
      </Box>
      <Box className='infoBox'>
        <Typography variant='h4' className='heading'>
          Last Login
        </Typography>
        <Typography className='content'>{user?.metadata?.lastSignInTime}</Typography>
      </Box>
      {userRole && (
        <Button
          variant='outlined'
          size='small'
          color='secondary'
          onClick={() =>
            window.open('https://billing.stripe.com/p/login/fZe5md4eg3WYcg04gg', '_blank')
          }
        >
          Manage Account
        </Button>
      )}
    </UerInfoCard>
  )
}

export default UserInfo
