import { Box, Typography, Container, Paper, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const PrivacyContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '70vh',
	padding: theme.spacing(4),
}))

const PrivacyPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(6),
	borderRadius: theme.spacing(2),
	maxWidth: 800,
	width: '100%',
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
	boxShadow: theme.palette.mode === 'dark'
		? '0 4px 20px rgba(0, 0, 0, 0.5)'
		: '0 4px 20px rgba(0, 0, 0, 0.1)',
}))

function PrivacyPolicy() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])

	return (
		<PrivacyContainer>
			<PrivacyPaper elevation={3}>
				<Typography
					variant='h3'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
						marginBottom: 4,
						fontWeight: 600,
					}}
				>
					Privacy Policy
				</Typography>

				<Stack spacing={3}>
					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
						}}
					>
						1. Information We Collect
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						We collect information that you provide directly to us, including account credentials, contact information, and usage data. This may include email addresses, names, and other details necessary for service provision and account management.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						2. How We Use Your Information
					</Typography>
					<Box component="ul" sx={{ pl: 2, mt: 1 }}>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 1.5,
								lineHeight: 1.8,
							}}
						>
							Provide, maintain, and improve our services
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 1.5,
								lineHeight: 1.8,
							}}
						>
							Process and complete transactions
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								lineHeight: 1.8,
							}}
						>
							Send technical notices and security alerts
						</Typography>
					</Box>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						3. Data Security
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						We implement robust security measures to protect your information, including encryption, secure socket layer technology, and regular security assessments. All stored credentials are encrypted using industry-standard algorithms.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						4. Data Sharing and Disclosure
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						We do not sell or rent your personal information to third parties. We may share your information only in specific circumstances, such as when required by law or with your explicit consent for service provision.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						5. Your Rights and Choices
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						You have the right to access, correct, or delete your personal information. You can also choose to opt-out of certain data collection practices. Contact our support team to exercise these rights or learn more about our data practices.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						6. Updates to This Policy
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and updating the "last modified" date.
					</Typography>
				</Stack>
			</PrivacyPaper>
		</PrivacyContainer>
	)
}

export default PrivacyPolicy
