import { useLogin } from '../../hooks/useLogin'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  Box,
  CircularProgress,
  useMediaQuery,
  Link as MuiLink,
  Stack,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { Google } from '@mui/icons-material'
import GoogleIcon from '../../images/google.png'
import { useLogout } from '../../hooks/useLogout'

const MuiGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px auto',
  height: '100%',
}))

const PageContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(3),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}))

const LoginBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    gap: '1rem',
  },
  '& > .MuiTypography-root.title': {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '& > .MuiTypography-root.txt': {
    textAlign: 'center',
    maxWidth: '490px',
    color: theme.palette.text.secondary,
  },
  '& > form': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1.2rem',
    background: theme.palette.background.paper,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 3px 11px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.15)'
        : '0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem',
    },
  },
}))

const MainForm = styled('form')(({ theme }) => ({
  '& > .error': {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '20px',
    fontSize: '16px',
    color: theme.palette.error.main,
    background: theme.palette.mode === 'dark' ? 'rgba(255, 0, 0, 0.15)' : 'pink',
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '4px',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px',
      fontSize: '12px',
    },
  },

  '& .MuiFormControl-root': {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fff',
      '& fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '8px',
      marginLeft: '4px',
      color: theme.palette.error.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  },

  '& .action': {
    textAlign: 'center',
    '& > button': {
      padding: theme.spacing(2),
      fontWeight: '700',
      textTransform: 'initial',
      fontSize: theme.typography.body1.fontSize,
      display: 'inline-flex',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        fontSize: '14px',
      },
      '& > span.MuiCircularProgress-root': {
        width: '20px !important',
        height: '20px !important',
        color: 'inherit',
        marginRight: '10px',
        marginTop: '2px',
      },
      '& img': {
        width: '23px',
        height: '23px',
        marginRight: '5px',
      },
    },
  },

  '& .MuiLink-root': {
    color: theme.palette.primary.main,
  },
}))

const LoginContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: '8px',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0px 4px 28px rgba(0, 0, 0, 0.4)'
      : '0px 4px 28px rgba(2, 2, 2, 0.1)',
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: '530px',

  '& .MuiTextField-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fff',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  },

  '& .MuiFormControlLabel-root': {
    color: theme.palette.text.primary,
  },

  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
}))

const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const { error: authError, login, handleGoogleSignIn, loading: googleLoading } = useLogin()
  const isSm = useMediaQuery('(max-width:600px)')
  const location = useLocation()
  const navigate = useNavigate()
  const logout = useLogout()
  const [loading, setLoading] = useState(false)

  const handleLoginSuccess = () => {
    navigate(location.state?.from?.pathname || '/projects', { replace: true })
    reset()
    setLoading(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    await login(data.email, data.password, handleLoginSuccess, logout)
    setLoading(false)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const [hiddenState, setHiddenState] = useState(false)
  return (
    <PageContainer maxWidth='md'>
      <LoginContainer>
        <LoginBox>
          <Typography className='title' variant='h2'>
            Login
          </Typography>
          <Typography className='txt' variant='body1' width='85%'>
            Securely manage and share your project credentials. Access your encrypted passwords, API
            keys, and sensitive data with our enterprise-grade platform.
          </Typography>
          <MainForm onSubmit={handleSubmit(onSubmit)}>
            {authError && <Typography className='error'>{authError?.message}</Typography>}
            <Stack direction='column' spacing={2}>
              <TextField
                type='email'
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                fullWidth
                placeholder='Email'
                autoComplete='off'
                error={!!errors.email}
                helperText={errors.email && 'This is required'}
                {...register('email', { required: true })}
              />
              <TextField
                type={hiddenState ? 'text' : 'password'}
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                placeholder='Password'
                fullWidth
                autoComplete='off'
                error={!!errors.password}
                helperText={errors.password && 'This is required'}
                {...register('password', { required: true })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ zIndex: 9999, position: 'relative' }}
                        aria-label='toggle password visibility'
                        onClick={() => setHiddenState(!hiddenState)}
                        edge='end'
                      >
                        {!hiddenState ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <MuiLink
              color='primary'
              variant='body2'
              sx={{
                width: 'auto',
                marginLeft: 'auto',
                textDecoration: 'none',
                textAlign: 'end',
                cursor: 'pointer',
              }}
              fontWeight='500'
              mt={{ xs: 0.4, sm: 0.5 }}
              onClick={() => navigate('/password-reset')}
            >
              Forgot password?
            </MuiLink>
            <Stack className='action' direction='column' spacing={2}>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                disabled={loading || !!authError}
                startIcon={loading && <CircularProgress size={20} />}
              >
                Login
              </Button>
              <Button
                type='button'
                variant='contained'
                fullWidth
                // disabled={loading || !!authError}
                color='secondary'
                sx={{
                  background: '#f5f8fc',
                  boxShadow: 'none !important',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: '#f5f8fc',
                    filter: 'brightness(0.97)',
                  },
                }}
                onClick={() => handleGoogleSignIn(handleLoginSuccess)}
                startIcon={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {googleLoading && (
                      <>
                        <CircularProgress size={20} />
                        &nbsp; &nbsp;
                      </>
                    )}
                    <img src={GoogleIcon} alt='google sign in' />
                  </span>
                }
              >
                Sign in with Google
              </Button>
            </Stack>
            <Typography textAlign='center' width='100%' variant='body1'>
              Don’t have an account?{' '}
              <MuiLink
                sx={{
                  textDecoration: 'none',
                  fontWeight: '700',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/register')}
              >
                Create account
              </MuiLink>
            </Typography>
          </MainForm>
        </LoginBox>
      </LoginContainer>
    </PageContainer>
  )
}

export default Login
