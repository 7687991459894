import { useRegister } from '../../hooks/useRegister'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import axios from 'axios'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import { useForm } from 'react-hook-form'
import {
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  Box,
  CircularProgress,
  Stack,
  Link as MuiLink,
  useMediaQuery,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import GoogleIcon from '../../images/google.png'
import { useLogin } from '../../hooks/useLogin'
import { getCustomClaimRole, getPlans, subscribeToPlan } from '../../firebase'
import Switch from '@mui/material/Switch'
import { useSearchParams } from 'react-router-dom'

const MuiGrid = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px auto',
  height: '100%',
}))

const RegisterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: '8px',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0px 4px 28px rgba(0, 0, 0, 0.4)'
      : '0px 4px 28px rgba(2, 2, 2, 0.1)',
  padding: theme.spacing(3),

  '& .MuiTextField-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fff',
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  },

  '& .MuiFormControlLabel-root': {
    color: theme.palette.text.primary,
  },

  '& .MuiToggleButtonGroup-root': {
    '& .MuiToggleButton-root': {
      color: theme.palette.text.primary,
      borderColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.12)',
      '&.Mui-selected': {
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
        color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
      },
    },
  },

  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },

  '& .MuiSwitch-root': {
    '& .MuiSwitch-track': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : '#fff',
    },
  },
}))

const RegisterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    gap: '1rem',
  },
  '& > .MuiTypography-root.title': {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '& > .MuiTypography-root.txt': {
    textAlign: 'center',
    maxWidth: '490px',
    color: theme.palette.text.secondary,
  },
  '& > form': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1.2rem',
    background: theme.palette.background.paper,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0px 3px 11px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.15)'
        : '0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem',
    },
  },
}))

const MainForm = styled('form')(({ theme }) => ({
  '& > .error': {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '20px',
    fontSize: '16px',
    color: theme.palette.error.main,
    background: theme.palette.mode === 'dark' ? 'rgba(255, 0, 0, 0.15)' : 'pink',
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '4px',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px',
      fontSize: '12px',
    },
  },

  '& .MuiFormControl-root': {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fff',
      '& fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '8px',
      marginLeft: '4px',
      color: theme.palette.error.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  },

  '& .MuiToggleButtonGroup-root': {
    '& .MuiToggleButton-root': {
      color: theme.palette.text.primary,
      borderColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.12)',
      '&.Mui-selected': {
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
        color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
      },
    },
  },

  '& .action': {
    textAlign: 'center',
    '& > button': {
      padding: theme.spacing(2),
      fontWeight: '700',
      textTransform: 'initial',
      fontSize: theme.typography.body1.fontSize,
      display: 'inline-flex',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        fontSize: '14px',
      },
      '& > span.MuiCircularProgress-root': {
        width: '20px !important',
        height: '20px !important',
        color: 'inherit',
        marginRight: '10px',
        marginTop: '2px',
      },
      '& img': {
        width: '23px',
        height: '23px',
        marginRight: '5px',
      },
    },
  },
}))

const PageContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(3),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}))

const Register = ({ setStripeLoader }) => {
  const { error: authError, signup } = useRegister()
  const { handleGoogleSignIn, loading: googleLoading } = useLogin()
  const isSm = useMediaQuery('(max-width:600px)')
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const [loaders, setLoaders] = useState([])
  const [alignment, setAlignment] = useState('monthly')
  const [teamSwitch, setTeamSwitch] = useState(
    location?.state?.from?.pathname.includes('teamInvite') ? false : location.state || false
  )
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const label = { inputProps: { 'aria-label': 'Switch demo' } }
  const FirstPromoterApiKey = process.env.REACT_APP_FIRSTPROMOTER_API_KEY
  const createPromoterUrl = 'https://firstpromoter.com/api/v1/promoters/create'
  const showPromoterUrl = 'https://firstpromoter.com/api/v1/promoters/show'
  const signUpAndLeadUrl = 'https://firstpromoter.com/api/v1/track/signup'
  const [referralIdError, setReferralIdError] = useState(false)
  const [refIdInput, setRefIdInput] = useState('')
  const [refIdVerify, setRefIdVerify] = useState(false)
  const [referralUser, setReferralUser] = useState({})
  const [searchParams] = useSearchParams()
  const registerQueryParam = searchParams.get('fpr')

  const handleLeadsAndSignUp = async (user) => {
    const requestBodyForLeadAndSignUp = new URLSearchParams({
      email: user?.email,
      uid: user?.uid,
      ref_id: referralUser?.default_ref_id,
    })
    try {
      const response = await axios.post(signUpAndLeadUrl, requestBodyForLeadAndSignUp, {
        headers: {
          'x-api-key': FirstPromoterApiKey,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      console.log('Lead SignUp successfully!', response?.data)
    } catch (error) {
      console.error('Error in Lead SignUp!', error)
    }
  }
  const handleVerifyReferral = async () => {
    //check referral promoter
    try {
      const response = await axios.get(showPromoterUrl, {
        headers: {
          'x-api-key': FirstPromoterApiKey,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ref_id: refIdInput || registerQueryParam,
        },
      })
      if (response) {
        setRefIdVerify(true)
        setReferralUser(response?.data)
        setReferralIdError(false)
      }
      console.log('Promoter found successfully!', response?.data)
    } catch (error) {
      setReferralIdError(true)
      console.error('Error in finding promoter:', error)
    }
  }
  const createPromoter = async (user) => {
    const requestBody = new URLSearchParams({
      email: user?.email,
      first_name: user?.displayName?.split(' ')[0],
      last_name: user?.displayName?.split(' ')?.slice(1)?.join(' '),
      cust_id: user?.uid,
    })
    try {
      const response = await axios.post(createPromoterUrl, requestBody, {
        headers: {
          'x-api-key': FirstPromoterApiKey,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      console.log('Promoter created successfully!', response?.data)
    } catch (error) {
      console.error('Error creating promoter:', error)
    }
  }
  const handleRegisterSuccess = async () => {
    teamSwitch
      ? handleSubscribePlan(
          plans?.find(
            (v) => v?.role === 'team' || v?.name?.toLowerCase() === 'cred management team plan'
          ).prices[alignment === 'monthly' ? 0 : 1]?.docId,
          1
        )
      : // let userRole = await getCustomClaimRole()
        (() => {
          // sign up email verification
          const auth = getAuth()
          sendEmailVerification(auth?.currentUser).then(() => {
            console.log('Test', auth?.currentUser)
          })
          if (referralUser)
            handleLeadsAndSignUp({
              ...auth?.currentUser,
              displayName:
                auth?.currentUser?.displayName || watch('firstName') + ' ' + watch('lastName'),
            })
          createPromoter({
            ...auth?.currentUser,
            displayName:
              auth?.currentUser?.displayName || watch('firstName') + ' ' + watch('lastName'),
          })
        })()
    // (async () => {
    //   const auth = getAuth();
    //   const userEmail = auth.currentUser.email;
    //   const actionCodeSettings = {
    //     url: "http://localhost:3000/custom-auth", // URL you want to be redirected to after email verification
    //   };
    //   try {
    //     const actionLink = await getAuth().generateEmailVerificationLink(
    //       userEmail,
    //       actionCodeSettings
    //     );
    //   } catch (error) {
    //     // handle errors
    //   }
    // })();

    navigate(location.state?.from?.pathname || '/dashboard', {
      replace: true,
    })
    setLoading(false)
    reset()
  }
  const [hiddenState, setHiddenState] = useState(false)
  const [hiddenState2, setHiddenState2] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    let { firstName, lastName, ...rest } = data
    let regData = { ...rest, displayName: firstName + ' ' + lastName }
    await signup(regData, handleRegisterSuccess)
    setLoading(false)
  }
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment || 'monthly')
  }

  useEffect(() => {
    ;(async (_) => {
      setPlans(await getPlans())
    })()
  }, [])

  useEffect(() => {
    if (location?.state?.from?.pathname.includes('teamInvite')) {
      let getEmail = location?.state?.from?.pathname.split('/')[3]
      setValue('email', getEmail)
    }
  }, [])

  useEffect(() => {
    if (!!plans?.length) {
      setLoaders(Array(plans.length).fill(false))
    }
  }, [plans])

  const handleSubscribePlan = async (priceDocId, ind) => {
    setStripeLoader(true)
    let loadersClone = [...loaders]
    loadersClone[ind] = true
    setLoaders(loadersClone)
    await subscribeToPlan(priceDocId, () => {
      setStripeLoader(false)
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  useEffect(() => {
    //check for referral id query parameter
    if (registerQueryParam) {
      setRefIdInput(registerQueryParam)
      handleVerifyReferral()
    }
  }, [])

  return (
    <PageContainer maxWidth='md'>
      <RegisterContainer>
        <RegisterBox>
          <Typography className='title' variant='h2'>
            Register
          </Typography>
          <Typography className='txt' variant='body1' width='85%'>
            Securely manage and share your project credentials. Access your encrypted passwords, API
            keys, and sensitive data with our enterprise-grade platform.
          </Typography>
          <MainForm onSubmit={handleSubmit(onSubmit)}>
            {authError && <Typography className='error'>{authError?.message}</Typography>}

            <Stack direction='row' spacing={2}>
              <TextField
                type='text'
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                fullWidth
                placeholder='First Name'
                autoComplete='off'
                error={!!errors.firstName}
                helperText={errors.firstName && 'This is required'}
                {...register('firstName', { required: true })}
              />
              <TextField
                type='text'
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                placeholder='Last Name'
                fullWidth
                autoComplete='off'
                error={!!errors.lastName}
                helperText={errors.lastName && 'This is required'}
                {...register('lastName', { required: true })}
              />
            </Stack>
            <Stack direction='column' spacing={2}>
              <TextField
                type='email'
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                fullWidth
                placeholder='Email'
                autoComplete='off'
                error={!!errors.email}
                helperText={errors.email && 'This is required'}
                {...register('email', { required: true })}
              />
              <TextField
                type={hiddenState ? 'text' : 'password'}
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                placeholder='Password'
                fullWidth
                autoComplete='off'
                error={!!errors.password}
                helperText={errors.password && 'This is required'}
                {...register('password', { required: true })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ zIndex: 9999, position: 'relative' }}
                        aria-label='toggle password visibility'
                        onClick={() => setHiddenState(!hiddenState)}
                        edge='end'
                      >
                        {!hiddenState ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type={hiddenState2 ? 'text' : 'password'}
                variant='outlined'
                inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 } }}
                placeholder='Confirm Password'
                fullWidth
                autoComplete='off'
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                {...register('confirmPassword', {
                  required: 'This is required!',
                  validate: (val) => {
                    if (watch('password') != val) {
                      return 'Your passwords do no match'
                    }
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ zIndex: 9999, position: 'relative' }}
                        aria-label='toggle password visibility'
                        onClick={() => setHiddenState2(!hiddenState2)}
                        edge='end'
                      >
                        {!hiddenState2 ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Box display={'flex'} alignItems='center' justifyContent={'center'}>
              <Typography variant='body1' mr={1}>
                Solo
              </Typography>
              <FormControlLabel
                label='Team'
                control={
                  <Switch
                    value=''
                    checked={teamSwitch}
                    onChange={() => setTeamSwitch(!teamSwitch)}
                    {...label}
                  />
                }
              />
            </Box>
            {teamSwitch &&
              plans?.length > 0 &&
              plans?.find(
                (v) => v?.role === 'team' || v?.name?.toLowerCase() === 'cred management team plan'
              ).prices?.length > 1 && (
                <Box textAlign={'center'}>
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    style={{ alignSelf: 'center', marginBottom: 20 }}
                  >
                    <ToggleButton value='monthly'>Monthly</ToggleButton>
                    <ToggleButton value='yearly'>Yearly</ToggleButton>
                  </ToggleButtonGroup>
                  <Typography variant='h6' color='initial' mt={1}>{`$${Math.round(
                    plans?.find(
                      (v) =>
                        v?.role === 'team' || v?.name?.toLowerCase() === 'cred management team plan'
                    ).prices[alignment === 'monthly' ? 0 : 1]?.unit_amount / 100
                  ).toFixed(2)}/${alignment === 'monthly' ? 'mth' : 'year'}`}</Typography>
                </Box>
              )}
            <MuiLink
              color='primary'
              variant='body2'
              sx={{
                width: 'auto',
                marginLeft: 'auto',
                textDecoration: 'none',
                textAlign: 'end',
                cursor: 'pointer',
              }}
              fontWeight='500'
              mt={{ xs: 0.4, sm: 0.5 }}
              onClick={() => navigate('/password-reset')}
            >
              Forgot password?
            </MuiLink>
            <Stack className='action' direction='column' spacing={2}>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                disabled={loading || !!authError || referralIdError}
                startIcon={loading && <CircularProgress size={20} />}
              >
                Register
              </Button>
              <Button
                type='button'
                variant='contained'
                fullWidth
                disabled={googleLoading}
                color='secondary'
                sx={{
                  background: '#f5f8fc',
                  boxShadow: 'none !important',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: '#f5f8fc',
                    filter: 'brightness(0.97)',
                  },
                }}
                onClick={() => handleGoogleSignIn(handleRegisterSuccess)}
                startIcon={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {googleLoading && (
                      <>
                        <CircularProgress size={20} />
                        &nbsp; &nbsp;
                      </>
                    )}
                    <img src={GoogleIcon} alt='google sign in' />
                  </span>
                }
              >
                Sign up with Google
              </Button>
            </Stack>
            <Typography textAlign='center' width='100%' variant='body1'>
              Alraedy have an account?{' '}
              <MuiLink
                sx={{
                  textDecoration: 'none',
                  fontWeight: '700',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/login')}
              >
                Login
              </MuiLink>
            </Typography>
          </MainForm>
        </RegisterBox>
      </RegisterContainer>
    </PageContainer>
  )
}

export default Register
