import React, { forwardRef, useEffect } from 'react'
import {
  Container,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Tooltip,
  Avatar,
} from '@mui/material'

import { styled } from '@mui/material/styles'
import { useState } from 'react'
import Groups2Icon from '@mui/icons-material/Group'
import CloseIcon from '@mui/icons-material/Close'

const MainContainer = styled(Container)(() => ({
  width: '100%',
  padding: 0,
}))
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    '& .team': {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.divider : '#8080802e'}`,
      padding: theme.spacing(1.2),
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      position: 'relative',
      gap: '10px',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
      boxShadow:
        theme.palette.mode === 'dark'
          ? '0px 0px 5px 1px rgba(255, 255, 255, 0.05)'
          : 'rgb(0 0 0 / 5%) 0px 0px 5px 1px',
      '& .first': {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        '& > .avatar': {
          position: 'relative',
          width: 'fit-content',
          borderRadius: '50%',
          '& > .MuiAvatar-root': {
            width: 60,
            height: 60,
            [theme.breakpoints.down('sm')]: {
              width: 50,
              height: 50,
            },
          },
        },
        '& > .txt': {
          flex: 1,
          paddingRight: '8px',
          '& > .MuiTypography-root': {
            overflowWrap: 'anywhere',
            '&.name': {
              fontWeight: 700,
              color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000',
              textTransform: 'capitalize',
              maxWidth: '86.5%',
              lineHeight: '22px',
            },
          },
        },
      },
    },
  },
  '& .MuiDialogActions-root': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    '& button': {
      minWidth: '80px !important',
    },
  },
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const OtherTeamsInfo = ({ teamOwners }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onClose = () => {
    handleClose()
  }

  const convertSinceDate = (date) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const getDate = new Date(date)
    return `${monthNames[getDate.getMonth()]} ${getDate.getFullYear()}`
  }

  return (
    <MainContainer>
      <Tooltip title='Teams'>
        <IconButton
          sx={{
            position: 'absolute',
            top: '2%',
            right: '3%',
            color: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.text.primary : 'rgb(255 255 255 / 71%)',
            p: 0.8,
          }}
          size='large'
          color='default'
          onClick={handleOpen}
        >
          <Groups2Icon fontSize='20px' />
        </IconButton>
      </Tooltip>
      {/* } */}
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xs: 'auto', sm: '440px', md: '540px' },
            borderRadius: '2px',
            p: { xs: 0.5, sm: 1 },
            m: { xs: 2, sm: 3 },
          },
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            px: { xs: 2 },
            fontSize: '1.5rem',
            color: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.text.primary : '#505962',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
          }}
        >
          Other Teams You're in
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'hidden',
            px: { xs: 2 },
            maxHeight: '526px',
            overflowY: 'auto',
          }}
        >
          {teamOwners?.length ? (
            teamOwners.map(({ owner, position, sinceDate }, idx) => (
              <Box className='team' key={owner.email}>
                <Box className='first'>
                  <Box className='avatar'>
                    <Avatar
                      src={owner?.photoURL || ' '}
                      alt={owner?.displayName?.toUpperCase() || owner?.email?.toUpperCase()}
                    />
                  </Box>
                  <Box className='txt'>
                    <Typography className='name' variant='body1'>
                      {(owner.managerInfo && owner.managerInfo.teamName) ||
                        `team ${
                          owner?.displayName?.length > 5
                            ? owner.displayName.split(' ')[0]
                            : owner?.displayName || 'NA'
                        }`}
                    </Typography>
                    <Typography
                      variant='body2'
                      pr={2}
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'inherit',
                      }}
                    >
                      {owner.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className='second'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0px',
                    '& >.MuiBox-root': {
                      display: 'flex',
                      gap: '10px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    },
                    '& > .MuiBox-root > .MuiBox-root': {
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                    },
                  }}
                >
                  <Box>
                    <Box>
                      <Typography
                        variant='body2'
                        fontWeight={500}
                        color={(theme) =>
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : '#505962'
                        }
                      >
                        Owner:
                      </Typography>
                      <Typography variant='body2'>{owner?.displayName}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='body2'
                        fontWeight={500}
                        color={(theme) =>
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : '#505962'
                        }
                      >
                        Position:
                      </Typography>
                      <Typography variant='body2'>{position || 'NA'}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Typography
                        variant='body2'
                        fontWeight={500}
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark' ? theme.palette.text.primary : '#505962',
                        }}
                      >
                        Total Members:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark'
                              ? theme.palette.text.secondary
                              : 'inherit',
                        }}
                      >
                        {owner?.team?.length}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='body2'
                        fontWeight={500}
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark' ? theme.palette.text.primary : '#505962',
                        }}
                      >
                        Since:
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark'
                              ? theme.palette.text.secondary
                              : 'inherit',
                        }}
                      >
                        {convertSinceDate(sinceDate) || 'NA'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant='body1'
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
                textAlign: 'center',
                py: 2,
              }}
            >
              You are not in anyone's team yet
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Stack columnGap={0.6} flexDirection='row' alignItems='center'>
            <Typography
              variant='body1'
              fontWeight={700}
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.text.primary : '#505962',
              }}
            >
              No of Teams:
            </Typography>
            <Typography
              variant='body1'
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'inherit',
              }}
            >
              {teamOwners?.length}
            </Typography>
          </Stack>
        </DialogActions>
      </StyledDialog>
    </MainContainer>
  )
}

export default OtherTeamsInfo
