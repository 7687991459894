import { Box, Typography, Container, Paper, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const TermsContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '70vh',
	padding: theme.spacing(4),
}))

const TermsPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(6),
	borderRadius: theme.spacing(2),
	maxWidth: 800,
	width: '100%',
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
	boxShadow: theme.palette.mode === 'dark'
		? '0 4px 20px rgba(0, 0, 0, 0.5)'
		: '0 4px 20px rgba(0, 0, 0, 0.1)',
}))

function TermsAndConditions() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])

	return (
		<TermsContainer>
			<TermsPaper elevation={3}>
				<Typography
					variant='h3'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
						marginBottom: 4,
						fontWeight: 600,
					}}
				>
					Terms and Conditions
				</Typography>

				<Stack spacing={3}>
					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
						}}
					>
						1. Acceptance of Terms
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						By accessing and using Cred Management's services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. These terms govern your use of our credential management platform and services.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						2. Service Description
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						Cred Management provides a secure platform for managing and sharing credentials within teams. Our services include credential storage, access control, and team management features. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						3. User Responsibilities
					</Typography>
					<Box component="ul" sx={{ pl: 2, mt: 1 }}>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 1.5,
								lineHeight: 1.8,
							}}
						>
							Maintain the confidentiality of your account credentials
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 1.5,
								lineHeight: 1.8,
							}}
						>
							Use the service in compliance with all applicable laws and regulations
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								lineHeight: 1.8,
							}}
						>
							Report any security vulnerabilities or unauthorized access immediately
						</Typography>
					</Box>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						4. Privacy and Security
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						We implement industry-standard security measures to protect your data. Your use of our services is also governed by our Privacy Policy. We encrypt all stored credentials and maintain strict access controls to ensure data security.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2,
						}}
					>
						5. Limitation of Liability
					</Typography>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
						}}
					>
						Cred Management is not liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our services. This includes but is not limited to loss of data, profits, or business opportunities.
					</Typography>
				</Stack>
			</TermsPaper>
		</TermsContainer>
	)
}

export default TermsAndConditions
