import React, { forwardRef, useEffect } from 'react'
import {
  Container,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  Dialog,
  Button,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
  Grid,
  FormHelperText,
  CircularProgress,
  IconButton,
  Box,
  useMediaQuery,
} from '@mui/material'

import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { doc, updateDoc } from 'firebase/firestore'
import { useForm } from 'react-hook-form'
import { db } from '../../../firebase/config'

const MainContainer = styled(Container)(() => ({
  width: '100%',
  padding: 0,
}))
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  },
  '& .MuiDialogTitle-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiDialogContent-root': {
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 2,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#FFFFFF',
        '& input': {
          padding: '11px 14px',
          color: theme.palette.text.primary,
        },
        '& fieldset': {
          borderColor:
            theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover fieldset': {
          borderColor:
            theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
        },
      },
      '& > p.MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginLeft: '2px',
      },
    },
  },
  '& .MuiDialogActions-root': {
    '& button': {
      minWidth: '80px !important',
      color: theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
      '&.MuiButton-contained': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const TeamInfoModal = ({ open, handleOpen, handleClose, user }) => {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMediaQuery(`(max-width:${700}px)`)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: '',
      teamName: '',
    },
  })

  const onClose = () => {
    handleClose()
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const userRef = doc(db, 'users', user.uid)
      await updateDoc(userRef, {
        managerInfo: data,
      })
      enqueueSnackbar('Information Updated!', { variant: 'success' })
      setLoading(false)
      handleClose()
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (user && user?.managerInfo) {
      let { companyName, teamName } = user.managerInfo
      reset({
        companyName: companyName || '',
        teamName: teamName || '',
      })
    }
  }, [user, reset])

  return (
    <MainContainer>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          // sx={{ position: "absolute", top: "3%", right: "3%", minWidth: "80px" }}
          variant='contained'
          size={isMobile ? 'small' : 'medium'}
          onClick={handleOpen}
          sx={{ textTransform: 'capitalize' }}
        >
          Team Information
        </Button>
      </Box>
      {/* } */}
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xs: 'auto', sm: '440px', md: '540px' },
            borderRadius: '2px',
            p: { xs: 0.5, sm: 1 },
            m: { xs: 2, sm: 3 },
          },
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            px: { xs: 2 },
          }}
        >
          Add Team Information
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'hidden',
            px: { xs: 2 },
          }}
        >
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={7} md={6}>
              <FormControl fullWidth>
                <FormLabel sx={{ pt: 2, pb: 1 }}>Company</FormLabel>
                <TextField
                  variant='outlined'
                  placeholder='Company Name'
                  sx={{ borderRadius: 0, bgcolor: '#FFFFFF' }}
                  fullWidth
                  error={!!errors.companyName}
                  {...register('companyName', {
                    minLength: { value: 3, message: 'Min length should be 3' },
                  })}
                />
                <FormHelperText>{errors?.companyName?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} md={6}>
              <FormControl fullWidth>
                <FormLabel sx={{ pt: 2, pb: 1 }}>Team</FormLabel>
                <TextField
                  variant='outlined'
                  placeholder='Team Name'
                  sx={{ borderRadius: 0, bgcolor: '#FFFFFF' }}
                  fullWidth
                  error={!!errors.teamName}
                  {...register('teamName', {
                    minLength: { value: 3, message: 'Min length should be 3' },
                  })}
                />
                <FormHelperText>{errors?.teamName?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' spacing={2}>
            <Button variant='outlined' onClick={onClose}>
              Close
            </Button>
            <Button
              variant='contained'
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              startIcon={
                loading && (
                  <CircularProgress style={{ width: '20px', height: '20px', color: '#fff' }} />
                )
              }
            >
              Save
            </Button>
          </Stack>
        </DialogActions>
      </StyledDialog>
    </MainContainer>
  )
}

export default TeamInfoModal
