import { Box, Typography, Container, Paper, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const StartedContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '70vh',
	padding: theme.spacing(4),
}))

const StartedPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(6),
	borderRadius: theme.spacing(2),
	maxWidth: 800,
	width: '100%',
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
	boxShadow: theme.palette.mode === 'dark'
		? '0 4px 20px rgba(0, 0, 0, 0.5)'
		: '0 4px 20px rgba(0, 0, 0, 0.1)',
}))

function GettingStarted() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])

	return (
		<StartedContainer>
			<StartedPaper elevation={3}>
				<Typography
					variant='h3'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
						marginBottom: 3,
						fontWeight: 600,
						textAlign: 'center'
					}}
				>
					Getting Started with Cred Management
				</Typography>
				<Stack spacing={3}>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.6
						}}
					>
						Welcome to Cred Management! Follow these simple steps to get started with secure credential management for your team:
					</Typography>

					<Box component="ol" sx={{ pl: 2 }}>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 2,
								lineHeight: 1.6
							}}
						>
							Create your account and set up your team profile. This will be your secure base for managing all credentials.
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 2,
								lineHeight: 1.6
							}}
						>
							Start a new project and add your credentials. You can store API keys, database passwords, and other sensitive information.
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 2,
								lineHeight: 1.6
							}}
						>
							Invite team members and set their access permissions. Control who can view, edit, or manage specific credentials.
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								lineHeight: 1.6
							}}
						>
							Monitor activity through our detailed audit logs. Track who accessed what and when, ensuring complete transparency.
						</Typography>
					</Box>
				</Stack>
			</StartedPaper>
		</StartedContainer>
	)
}

export default GettingStarted
