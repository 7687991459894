import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'

const StyledWelcomedBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
  padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  margin: '0rem auto',
  textAlign: 'center',
  boxShadow: 'rgb(0 0 0 / 5%) 0px 0px 5px 1px',
  '& .MuiButton-contained': {
    padding: '10px 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 1rem',
    },
  },
}))

const WelcomeBox = ({ projectsExists, title }) => {
  const isSm = useMediaQuery('(max-width:650px)')

  return (
    <StyledWelcomedBox
      sx={{
        width: { xs: 'auto', sm: '350px', md: '450px' },
      }}
    >
      <img src='/icons/document-report.svg' alt='document-report' />
      <Typography
        sx={{
          color: (theme) => (theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000'),
          fontWeight: 700,
          fontSize: 24,
        }}
      >
        {title || 'Welcome to Cred Management!'}
      </Typography>
      <Typography
        variant='body2'
        py={2}
        sx={{
          color: (theme) =>
            theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
        }}
      >
        Securely manage and share your project credentials with your team. Create projects, store
        sensitive information, and collaborate efficiently.
      </Typography>
      <Button
        variant='contained'
        color='primary'
        component={Link}
        to={projectsExists ? '/projects' : '/add-project'}
        size={isSm ? 'small' : 'large'}
      >
        {projectsExists ? 'See Your Projects' : 'Create Your First Project'}
      </Button>
    </StyledWelcomedBox>
  )
}

export default WelcomeBox
