import { Box, Typography, Container, Paper, Stack, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const BlogContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '70vh',
	padding: theme.spacing(4),
}))

const BlogPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(6),
	borderRadius: theme.spacing(2),
	maxWidth: 800,
	width: '100%',
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
	boxShadow: theme.palette.mode === 'dark'
		? '0 4px 20px rgba(0, 0, 0, 0.5)'
		: '0 4px 20px rgba(0, 0, 0, 0.1)',
}))

const BlogMeta = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(4),
	color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.6)',
	fontSize: '0.9rem',
}))

function Blog() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])

	return (
		<BlogContainer>
			<BlogPaper elevation={3}>
				<Typography
					variant='h3'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
						marginBottom: 2,
						fontWeight: 600,
					}}
				>
					Best Practices for Team Credential Management
				</Typography>

				<BlogMeta>
					<Stack direction="row" spacing={2} alignItems="center">
						<Typography>By Sarah Chen</Typography>
						<Divider orientation="vertical" flexItem />
						<Typography>October 15, 2023</Typography>
						<Divider orientation="vertical" flexItem />
						<Typography>5 min read</Typography>
					</Stack>
				</BlogMeta>

				<Stack spacing={3}>
					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8
						}}
					>
						In today's fast-paced development environment, managing team credentials effectively is crucial for both security and productivity. As teams grow and projects become more complex, the challenge of securing and sharing access credentials becomes increasingly important.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2
						}}
					>
						The Challenge of Modern Credential Management
					</Typography>

					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8
						}}
					>
						Development teams often juggle dozens of credentials across various services - from API keys and database passwords to cloud service credentials. The traditional approach of sharing these through chat messages or spreadsheets is not only insecure but also inefficient.
					</Typography>

					<Typography
						variant='h5'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
							fontWeight: 500,
							marginTop: 2
						}}
					>
						Key Security Measures
					</Typography>

					<Box component="ul" sx={{ pl: 2, mt: 1 }}>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 1.5,
								lineHeight: 1.8
							}}
						>
							Implement role-based access control (RBAC) to ensure team members only access credentials they need
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								mb: 1.5,
								lineHeight: 1.8
							}}
						>
							Use encryption at rest and in transit to protect sensitive credential data
						</Typography>
						<Typography
							component="li"
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								lineHeight: 1.8
							}}
						>
							Maintain detailed audit logs of all credential access and changes
						</Typography>
					</Box>

					<Typography
						variant='body1'
						sx={{
							color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
							lineHeight: 1.8,
							marginTop: 2
						}}
					>
						By following these best practices and using modern credential management solutions, teams can significantly improve their security posture while maintaining development velocity. The key is finding the right balance between security and accessibility.
					</Typography>
				</Stack>
			</BlogPaper>
		</BlogContainer>
	)
}

export default Blog
