import { Box, Typography, Container, Paper, Stack, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const HelpContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '70vh',
	padding: theme.spacing(4),
}))

const HelpPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(6),
	borderRadius: theme.spacing(2),
	maxWidth: 800,
	width: '100%',
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
	boxShadow: theme.palette.mode === 'dark'
		? '0 4px 20px rgba(0, 0, 0, 0.5)'
		: '0 4px 20px rgba(0, 0, 0, 0.1)',
}))

const HelpSection = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(4),
	'&:last-child': {
		marginBottom: 0
	}
}))

function HelpCenter() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])

	return (
		<HelpContainer>
			<HelpPaper elevation={3}>
				<Typography
					variant='h3'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
						marginBottom: 4,
						fontWeight: 600,
						textAlign: 'center'
					}}
				>
					Help Center
				</Typography>

				<Stack spacing={4}>
					<HelpSection>
						<Typography
							variant='h5'
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
								marginBottom: 2,
								fontWeight: 500
							}}
						>
							Frequently Asked Questions
						</Typography>
						<Typography
							variant='body1'
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								lineHeight: 1.6,
								marginBottom: 2
							}}
						>
							Find quick answers to common questions about using Cred Management:
						</Typography>
						<Box component="ul" sx={{ pl: 2, mt: 2 }}>
							<Typography
								component="li"
								sx={{
									color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
									mb: 1.5,
									lineHeight: 1.6
								}}
							>
								How secure is my credential data? We use enterprise-grade encryption and follow industry best practices for data security.
							</Typography>
							<Typography
								component="li"
								sx={{
									color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
									mb: 1.5,
									lineHeight: 1.6
								}}
							>
								Can I control who sees what credentials? Yes, our role-based access control lets you set precise permissions for each team member.
							</Typography>
							<Typography
								component="li"
								sx={{
									color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
									lineHeight: 1.6
								}}
							>
								What happens if someone leaves the team? You can instantly revoke access and our system will log all credential access history.
							</Typography>
						</Box>
					</HelpSection>

					<HelpSection>
						<Typography
							variant='h5'
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
								marginBottom: 2,
								fontWeight: 500
							}}
						>
							Need Additional Support?
						</Typography>
						<Typography
							variant='body1'
							sx={{
								color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
								lineHeight: 1.6
							}}
						>
							Our support team is available Monday through Friday, 9 AM - 6 PM EST. Contact us through:
							<Box component="ul" sx={{ mt: 1 }}>
								<li>Email: support@credmanagement.com</li>
								<li>Live Chat: Available during business hours</li>
								<li>Documentation: Visit our comprehensive guides at docs.credmanagement.com</li>
							</Box>
						</Typography>
					</HelpSection>
				</Stack>
			</HelpPaper>
		</HelpContainer>
	)
}

export default HelpCenter
