import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Grid,
  Paper,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  Typography,
  Button,
  Avatar,
  useMediaQuery,
  Stack,
  LinearProgress,
  CircularProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import BasicBreadcrumbs from '../../../components/Breadcrumb/Breadcrumb'
import MultipleSelectCheckmarks from '../../../components/CheckboxAutocomplete/Autocomplete'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  addDoc,
  collection,
  onSnapshot,
  doc,
  getDocs,
  query,
  where,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import { db } from '../../../firebase/config'
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import checkIcon from '../../../images/checkIcon.png'
import uncheckIcon from '../../../images/uncheckIcon.png'
import { useCollection } from '../../../hooks/useCollection'
import { useSnackbar } from 'notistack'
import { getCustomClaimRole } from '../../../firebase'
import { useMyTeamContext } from '../../../hooks/useMyTeamContext'
import DynamicFields from './components/DynamicFields'

const MainContariner = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'index',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& .subContainer': {
    marginTop: '2rem',
    minHeight: '70vh',
    width: '88vw',
    boxShadow: '0px 0px 35px rgba(22, 22, 22, 0.1)',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
  },
  '& .MuiFormControl-root': {
    '& .MuiFormLabel-root': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000',
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#FFFFFF',
      '& input': {
        padding: '11px 14px',
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
      },
    },
    '& > p.MuiFormHelperText-root': {
      color: 'red',
      marginLeft: '2px',
    },
  },
  '& .MuiButton-contained': {
    padding: '10px 2.5rem',
    '&.select-file': {
      padding: '4px 1rem',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#6F6C90',
      position: 'absolute',
      zIndex: 1,
      top: 65,
      p: '5px 15px!important',
      left: 10,
    },
  },

  '& .keyValuePair': {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.divider : '#E4E4E7'}`,
    justifyContent: 'space-between',
  },

  '& .leftContainer': {
    position: 'relative',
    height: '100%',
    minHeight: '430px',
    '& .project-type': {
      border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.divider : '#E4E4E7'}`,
      borderRadius: '2px',
      padding: '11px 14px',
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(1.5),
      marginBottom: '10px',
      cursor: 'pointer',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
      '&.selected': {
        borderColor: theme.palette.primary.main,
      },
      '& img': {
        width: '25px',
        height: '25px',
      },
      '& .MuiTypography-root': {
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
      },
    },
  },
}))

const breadcrumbConstant = [{ title: 'Projects', url: '/dashboard' }, { title: 'Create New' }]

function ProjectDetails() {
  const { documents: users } = useCollection('users')
  const [userRole, setUserRole] = useState(undefined)
  const isSm = useMediaQuery('(max-width:700px)')
  const { user } = useAuthContext()
  const { myTeam } = useMyTeamContext()
  const navigate = useNavigate()
  const { dispatch } = useMyTeamContext()
  const { state } = useLocation()
  const { id } = useParams()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openFile, setFileOpen] = useState(false)
  const storage = getStorage()
  const [fileLocalState, setFileLocalState] = useState(state?.files || [])
  const [progress, setProgress] = useState(0)
  // 'file' comes from the Blob or File API
  const { enqueueSnackbar } = useSnackbar()
  // enqueueSnackbar("Data Updated!", { variant: "success" });
  const usersRef = collection(db, 'users')

  useEffect(() => {
    ;(async (_) => {
      if (user) {
        let role = await getCustomClaimRole()
        setUserRole(role)
      }
    })()
  }, [user])

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    clearErrors,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project_name: '',
      project_type: 'solo',
      project_info: '',
      fileDescription: '',
      files: [],
      extraInfo: [{ name: '', url: '', key: '', value: '' }],
    },
  })
  const [filename, setFilename] = useState('')
  const [dltFiles, setDltFiles] = useState([])
  const [acceptedTeam, setAcceptedTeam] = useState([])
  const [assignedTeam, setAssignedTeam] = React.useState([])
  const [saveLoading, setSaveLoading] = useState(false)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'extraInfo',
  })

  const handleFileUpload = (data) => {
    setLoading(true)
    let fileUrls = []
    let filteredData = fileLocalState?.filter(
      (val, i) => typeof val.url !== 'string' && fileLocalState?.length - 1 === i
    )

    if (dltFiles.length > 0) {
      setValue(
        'files',
        fileLocalState?.filter(
          (val) => !dltFiles.includes(typeof val.url === 'string' ? val.url : val.url.name)
        )
      )
      setFileLocalState((prev) => prev.filter((v) => !dltFiles.includes(v.url)))
    }
    if (!filename) {
      setLoading(false)
      setFileOpen(false)
      setDltFiles([])
    } else {
      filteredData.map(async ({ url: v }, i) => {
        const metadata = {
          contentType: v.type,
        }
        try {
          const storageRef = ref(storage, `project-files/${v.name}`)
          const uploadTask = uploadBytesResumable(storageRef, v, metadata)
          let downloadURL = ''
          let downloadMetadata = null
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setProgress(progress)
            },
            (err) => enqueueSnackbar(err.message, { variant: 'error' }),
            async () => {
              downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
              downloadMetadata = await getMetadata(uploadTask.snapshot.ref)
              const finalData = {
                url: downloadURL,
                metadata: downloadMetadata.updated,
                fileDescription: getValues('fileDescription'),
              }
              fileUrls.push(finalData)
              setFileLocalState((prev) =>
                prev.map((v, i) => {
                  if (prev.length - 1 === i) {
                    return {
                      ...v,
                      fileDescription: getValues('fileDescription'),
                    }
                  }

                  return v
                })
              )
              if (filteredData?.length - 1 === i) {
                let stringUrls = fileLocalState?.filter(
                  (val) => val && val?.url && typeof val.url === 'string' && val && val?.url
                )
                setValue(
                  'files',
                  [...stringUrls, ...fileUrls].filter(
                    (v) => !dltFiles.includes(typeof v.url === 'string' ? v.url : v.url.name)
                  )
                )
                onSubmit({
                  ...data,
                  files: [...stringUrls, ...fileUrls].filter(
                    (v) => !dltFiles.includes(typeof v.url === 'string' ? v.url : v.url.name)
                  ),
                })
                setLoading(false)
                setDltFiles([])
                setFilename('')
              }
            }
          )
        } catch (err) {
          setProgress(0)
          setLoading(false)
          setDltFiles([])
          setFilename('')
        }
      })
    }
  }
  useEffect(() => {
    if (progress === 100 && !loading) {
      setFileOpen(false)
      setProgress(0)
      setValue('fileDescription', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, loading])

  const onSubmit = async (data) => {
    let finalData = {
      ...data,
      team: data.project_type === 'team' ? assignedTeam : [],
    }
    setSaveLoading(true)
    try {
      if (id && state) {
        const projectRef = doc(db, 'projects', id)
        await updateDoc(projectRef, finalData)
        state?.team.forEach(async (memberid) => {
          if (assignedTeam.includes(memberid)) return false
          const payloadNoti = {
            title: `Project`,
            message: `You are removed from a (${data.project_name})`,
            sender_id: user.uid,
            receiver_id: memberid,
            seen: false,
            createdAt: serverTimestamp(),
          }
          const notiRef = collection(db, 'notifications')
          await addDoc(notiRef, payloadNoti)
        })
        enqueueSnackbar('Project Updated!', { variant: 'success' })
      } else {
        const projRef = collection(db, 'projects')
        await addDoc(projRef, {
          ...finalData,
          uid: user.uid,
          team: assignedTeam,
        })
        enqueueSnackbar('Project Created!', { variant: 'success' })
      }
      assignedTeam.forEach(async (memberid) => {
        if (state?.team.includes(memberid)) return false
        const payloadNoti = {
          title: `Project`,
          message: `You are added to a (${data.project_name})`,
          sender_id: user.uid,
          receiver_id: memberid,
          seen: false,
          createdAt: serverTimestamp(),
        }
        const notiRef = collection(db, 'notifications')
        await addDoc(notiRef, payloadNoti)
      })
      setSaveLoading(false)
      reset()
      navigate('/projects')
    } catch (error) {
      setSaveLoading(false)
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const handleSumbmit = (data) => {
    try {
      setSaveLoading(true)
      let stateFiles = state?.files || []
      if (fileLocalState.length > stateFiles.length && filename) {
        handleFileUpload(data)
      } else {
        onSubmit(data)
      }
    } catch (error) {
      setSaveLoading(false)
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const handleCancel = () => {
    navigate('/projects')
  }

  useEffect(() => {
    if (state && id) {
      const { project_name, project_type, extraInfo, project_info, files } = state
      return reset({
        project_name,
        project_info,
        project_type,
        extraInfo,
        fileDescription: '',
        files,
      })
    } else {
      !id &&
        reset({
          project_name: '',
          project_type: userRole === 'team' ? 'team' : 'solo',
          project_info: '',
          fileDescription: '',
          files: [],
          extraInfo: [{ name: '', url: '', key: '', value: '' }],
        })
    }
  }, [state, id, reset, userRole])

  const getTeamMembers = () => {
    if (myTeam && myTeam.length > 0) {
      const filteredTeam = myTeam.filter((member) => member.status === 'accepted')
      setAcceptedTeam(filteredTeam)
      if (state && id) {
        setAssignedTeam(state?.team || [])
      }
    }
  }

  useEffect(() => {
    myTeam && getTeamMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTeam])

  useEffect(() => {
    console.log(myTeam)
  }, [myTeam])

  const project_type_options = [
    {
      type: 'team',
      title: 'Team',
      description: 'Share with your own created team',
    },
    {
      type: 'solo',
      title: 'Solo',
      description: 'Keep it private, for personal purpose',
    },
  ]

  //if not getting team from context
  useEffect(() => {
    if (myTeam || !user) return
    const unsub = onSnapshot(collection(db, 'users', user.uid, 'team'), async (myTeamSnap) => {
      if (myTeamSnap.docs.length > 0) {
        const membersArr = []
        const teamUsers = []
        myTeamSnap.docs.forEach(async (member) => {
          membersArr.push({ ...member.data() })
          const queryOnMember = query(
            collection(db, 'users'),
            where('email', '==', member.data().email)
          )
          teamUsers.push(getDocs(queryOnMember))
        })
        let getTeamUsers = await Promise.all(
          teamUsers.map(async (queryUserSnap) => {
            let getUser = await queryUserSnap
            if (queryUserSnap.empty) return false
            getUser = getUser.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))[0]
            return getUser
          })
        )
        getTeamUsers = membersArr.map((member) => {
          return {
            ...member,
            ...(getTeamUsers.find((owner) => owner?.email === member.email) || {}),
          }
        })
        dispatch({ type: 'setMyTeam', payload: getTeamUsers })
      }
    })
    return () => unsub()
  }, [])
  return (
    <MainContariner>
      <Box component='form' onSubmit={handleSubmit(handleSumbmit)}>
        <Grid container component={Paper} className='subContainer'>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              px: 3,
              py: 2,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? theme.palette.background.default : '#F9FAFC',
            }}
          >
            <BasicBreadcrumbs list={breadcrumbConstant} />
            <FormControl fullWidth>
              <FormLabel sx={{ pt: 3, pb: 1 }}>Project Name</FormLabel>
              <TextField
                variant='outlined'
                placeholder='Project Name'
                sx={{
                  borderRadius: 0,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
                  },
                }}
                fullWidth
                error={!!errors.project_name}
                {...register('project_name', { required: true })}
              />
              <FormHelperText>{errors.project_name && 'This is required'}</FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel sx={{ pt: 3, pb: 1 }}>Project Description</FormLabel>
              <TextField
                variant='outlined'
                placeholder='Description your project'
                sx={{
                  borderRadius: 0,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
                  },
                }}
                fullWidth
                multiline
                rows={4}
                error={!!errors.project_info}
                {...register('project_info', { required: true })}
              />
              <FormHelperText>{errors.project_info && 'This is required'}</FormHelperText>
            </FormControl>
            {fields.map((field, index) => (
              <DynamicFields
                index={index}
                field={field}
                fields={fields}
                clearErrors={clearErrors}
                control={control}
                watch={watch}
                setError={setError}
                append={append}
                remove={remove}
                errors={errors[`extraInfo.${index}`]}
                stateValues={state?.extraInfo?.[index]}
              />
            ))}
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
              }}
            >
              <FormControl fullWidth className='upload-file'>
                <FormLabel sx={{ pt: 3, pb: 1 }}>Attachment</FormLabel>
                <TextField
                  id='select-file'
                  variant='outlined'
                  type={'file'}
                  placeholder='Project Name'
                  sx={{
                    borderRadius: 0,
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? theme.palette.background.paper : '#FFFFFF',
                      '& input': {
                        color: (theme) =>
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
                      },
                    },
                  }}
                  fullWidth
                  onChange={(e) => {
                    const file = e.target.files[0]
                    if (!file) {
                      return
                    }
                    const { name } = file
                    setFilename(name)

                    const reader = new FileReader()
                    reader.onload = (evt) => {
                      if (!evt?.target?.result) {
                        return
                      }
                      // const { result } = evt.target
                    }
                    setFileLocalState((prev) => [...prev, { url: file, metadata: file }])
                  }}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
            </Box>
            {progress > 0 && progress < 100 && (
              <LinearProgress variant='determinate' value={progress} />
            )}
          </Grid>
          <Grid item xs={12} md={4} sx={{ py: 2, px: 3 }}>
            <Typography variant='body1' sx={{ fontSize: 16 }}>
              Set Project Attributes
            </Typography>
            <Box className='leftContainer'>
              <FormControl fullWidth>
                <Typography
                  sx={{
                    pt: 3,
                    pb: 1,
                    color: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000',
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Select Project Type
                </Typography>
                {project_type_options
                  .filter((v, i) => (userRole !== 'team' ? i === 1 : true))
                  .map((obj) => (
                    <Box
                      className={`project-type ${
                        watch('project_type') === obj.type ? 'selected' : ''
                      }`}
                      key={obj.type}
                      onClick={() => {
                        setValue('project_type', obj.type)
                      }}
                    >
                      <img
                        src={getValues('project_type') === obj.type ? checkIcon : uncheckIcon}
                        alt='tick'
                      />{' '}
                      <Stack>
                        <Typography variant='body2' color='black' fontWeight='500'>
                          {obj.title} Project
                        </Typography>
                        <Typography variant='caption' color='#6F6C90'>
                          {obj.description}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                <FormHelperText
                  sx={{
                    color: (theme) => theme.palette.text.primary + ' !important',
                  }}
                >
                  {watch('project_type') === 'solo' && "Note: You can't add team in solo"}
                </FormHelperText>
              </FormControl>
              {watch('project_type') === 'team' && (
                <React.Fragment>
                  <FormControl fullWidth>
                    <FormLabel
                      sx={{
                        pt: 3,
                        pb: 1,
                        color: '#000',
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      Assign
                    </FormLabel>
                    <MultipleSelectCheckmarks
                      placeholder={'Search team members'}
                      teams={acceptedTeam || []}
                      assignedTeam={watch('project_type') === 'team' ? assignedTeam : []}
                      setAssignedTeam={setAssignedTeam}
                      project_type={watch('project_type')}
                    />
                  </FormControl>
                  <Box sx={{ position: 'relative', mt: 1, minHeight: '60px' }}>
                    {assignedTeam.length > 0 &&
                      assignedTeam
                        .map((id) => {
                          return acceptedTeam.find((obj) => obj.uid === id)
                        })
                        .map((v, idx) => (
                          <Avatar
                            key={idx}
                            src={v?.photoURL || ' '}
                            alt={v?.displayName?.toUpperCase() || v?.email?.toUpperCase()}
                            sx={{
                              border: '1px solid #FFFFFF',
                              position: 'absolute',
                              left: idx * 30,
                            }}
                          />
                        ))}
                  </Box>
                </React.Fragment>
              )}
              <Box
                sx={{
                  // position: "absolute",
                  // bottom: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  // mt: "auto",
                  mt: 2,
                }}
              >
                <Button variant='contained' color={'secondary'} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={saveLoading}
                  startIcon={
                    saveLoading && (
                      <CircularProgress style={{ width: '20px', height: '20px', color: '#fff' }} />
                    )
                  }
                >
                  {id && state ? 'Update' : 'Create'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainContariner>
  )
}

export default ProjectDetails
