import React, { useState } from 'react'
import {
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  Link as MuiLink,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { auth } from '../../../firebase/config'
import { confirmPasswordReset } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const MainForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& > .error': {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '20px',
    fontSize: '16px',
    color: theme.palette.error.main,
    background: theme.palette.mode === 'dark' ? 'rgba(255, 0, 0, 0.15)' : 'pink',
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '4px',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '3px',
      fontSize: '12px',
    },
  },

  '& .MuiFormControl-root': {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#fff',
      '& fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '8px',
      marginLeft: '4px',
      color: theme.palette.error.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
  },
  '& .action': {
    textAlign: 'center',
    '& > button': {
      padding: theme.spacing(2),
      fontWeight: '700',
      textTransform: 'initial',
      fontSize: theme.typography.body1.fontSize,
      display: 'inline-flex',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        fontSize: '14px',
      },
      '& > span.MuiCircularProgress-root': {
        width: '20px !important',
        height: '20px !important',
        color: 'inherit',
        marginRight: '10px',
        marginTop: '2px',
      },
    },
  },
}))

function ResetPassword({ actionCode }) {
  const [authError, setAuthError] = useState()
  const [hiddenState, setHiddenState] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (values) => {
    try {
      const resp = await confirmPasswordReset(auth, actionCode, values.newPassword)
      console.log('sucessfully updated')
      setAuthError(null)
      setSuccessMessage(true)
      setTimeout(() => {
        setSuccessMessage(false)
      }, 4000)
    } catch (error) {
      setAuthError(error)
      console.log(error)
    }
  }

  return (
    <Container
      className='custom-email-verify'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '30px',
        minHeight: '100vh',
      }}
    >
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item>
          <Typography variant='h5' sx={{ color: (theme) => theme.palette.text.primary }}>
            Set Your New Password
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          item
          xs={10}
          sm={5}
          sx={{
            padding: '15px',
            backgroundColor: (theme) => theme.palette.background.paper,
            boxShadow: (theme) =>
              theme.palette.mode === 'dark'
                ? '0px 3px 11px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.15)'
                : '0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)',
            borderRadius: '4px',
          }}
        >
          <MainForm onSubmit={handleSubmit(onSubmit)}>
            {(authError || successMessage) && (
              <Typography
                className={authError ? 'error' : ''}
                sx={{
                  color: (theme) =>
                    authError ? theme.palette.error.main : theme.palette.success.main,
                }}
              >
                {authError?.message || 'Updated Successfully'}
              </Typography>
            )}
            <TextField
              type={hiddenState ? 'text' : 'password'}
              variant='outlined'
              fullWidth
              placeholder='New Password'
              autoComplete='off'
              error={!!errors.newPassword}
              helperText={errors.newPassword && 'This is required'}
              {...register('newPassword', { required: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ zIndex: 9999, position: 'relative' }}
                      aria-label='toggle password visibility'
                      onClick={() => setHiddenState(!hiddenState)}
                      edge='end'
                    >
                      {!hiddenState ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type='submit'
              variant='contained'
              fullWidth
              size='medium'
              sx={{ padding: '10px 0' }}
            >
              Submit
            </Button>
          </MainForm>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ResetPassword
