import { Box, Typography, Container, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'

const ContactContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '70vh',
	padding: theme.spacing(4),
}))

const ContactPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(6),
	borderRadius: theme.spacing(2),
	maxWidth: 800,
	width: '100%',
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
	boxShadow: theme.palette.mode === 'dark'
		? '0 4px 20px rgba(0, 0, 0, 0.5)'
		: '0 4px 20px rgba(0, 0, 0, 0.1)',
}))

function ContactUs() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])

	return (
		<ContactContainer>
			<ContactPaper elevation={3}>
				<Typography
					variant='h3'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
						marginBottom: 3,
						fontWeight: 600,
						textAlign: 'center'
					}}
				>
					Contact Us
				</Typography>
				<Typography
					variant='body1'
					sx={{
						color: theme => theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.7)',
						textAlign: 'center',
						lineHeight: 1.6,
						maxWidth: 600,
						margin: '0 auto'
					}}
				>
					Have questions about Cred Management or need assistance? Our support team is here to help!
					Whether you need help with credential management, team access controls, or have questions
					about our security features, we're just a message away. For fastest response, email us
					at support@credmanagement.com or use our live chat during business hours (9 AM - 6 PM EST).
				</Typography>
			</ContactPaper>
		</ContactContainer>
	)
}

export default ContactUs
