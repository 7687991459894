import { useCallback, useEffect, useState } from 'react'
import { auth, bucket } from '../../../firebase/config'
import { updateProfile } from 'firebase/auth'
import { useAuthContext } from '../../../hooks/useAuthContext'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import {
  ref,
  // snapshot,
  // uploadBtyes,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'

import { styled, useTheme } from '@mui/material/styles'
import {
  Box,
  Typography,
  Container,
  Grid,
  Avatar,
  TextField,
  Button,
  LinearProgress,
  FormControl,
  FormLabel,
  FormHelperText,
  InputAdornment,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useUpdate } from '../../../hooks/useUpdate'
import { useCollection } from '../../../hooks/useCollection'
import { useForm, Controller } from 'react-hook-form'

const ProfileInfoCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'cardWrapped',
})(({ theme, cardWrapped }) => ({
  background:
    theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.light,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0px 3px 11px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.15)'
      : '0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)',
  borderRadius: cardWrapped ? '0 0 3px 3px' : '0 3px 3px 0',
  padding: '25px 15px',
  border: `1px solid ${
    theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.primary.light
  }`,
  minWidth: '400px',
  ['@media (max-width:1100px)']: {
    minWidth: '300px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 10px',
  },
  '& > form': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    padding: '0px 10px',
    '&>*': {
      marginBottom: '20px',
    },
    '&>.MuiTypography-root': {
      textTransform: 'capitalize',
      fontWeight: '700',
      letterSpacing: '1px',
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#fff',
    },
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 2,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#FFFFFF',
        '& input': {
          color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'rgba(0, 0, 0, 0.87)',
          '&::placeholder': {
            color:
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.6)',
            opacity: 1,
          },
        },
        '& fieldset': {
          borderColor:
            theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover fieldset': {
          borderColor:
            theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.87)',
        },
        '&.Mui-focused fieldset': {
          borderColor:
            theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
        },
      },
      '& > p.MuiFormHelperText-root': {
        color: theme.palette.error.main,
        marginLeft: '2px',
      },
    },
  },
}))
const ProgressBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  width: '-webkit-fill-available',
  '&>.MuiTypography-root': {
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#fff',
  },
  '&>.MuiLinearProgress-root': {
    width: '100%',
    height: '6px',
    borderRadius: 3,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)',
    '&>.MuiLinearProgress-bar': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : '#fff',
    },
  },
  '&>button': {
    textTransform: 'capitalize',
    borderRadius: 1,
    minWidth: '45px',
    color: '#fff',
    fontSize: '8px',
    padding: '2px',
    letterSpacing: '0.5px',
    background:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.2)',
    '&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.primary.dark : 'rgba(255, 255, 255, 0.3)',
    },
  },
}))

const Profile = ({
  refrence,
  cardWrapped,
  thumbnailUrl,
  setThumbnailUrl,
  user,
  displayName,
  setDisplayName,
}) => {
  const theme = useTheme()
  const { documents: currentUser } = useCollection('users', ['email', '==', user.email])

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    clearErrors,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayName: displayName,
      phone: '',
    },
  })

  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)
  const { updateSetting, error, loading } = useUpdate()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!!currentUser && currentUser?.length > 0) {
      let { displayName, phone } = currentUser[0]
      reset({ displayName, phone })
    }
  }, [currentUser])

  const onSubmit = async (data) => {
    setProgress(0)
    setThumbnailError(null)

    let payload = {
      ...data,
      photoURL: thumbnailUrl,
    }

    if (data.displayName && thumbnailUrl) {
      payload.displayName = data.displayName
      payload.photoURL = thumbnailUrl
    } else if (thumbnailUrl) {
      payload.photoURL = thumbnailUrl
    } else if (data.displayName) {
      payload.displayName = displayName
    }

    await updateSetting(user, 'users', payload)
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      setThumbnail(null)

      let selected = acceptedFiles[0]

      if (!selected) {
        setThumbnailError('select image file')
        return
      }

      if (!selected.type.includes('image')) {
        setThumbnailError('must be an image file')
        return
      }

      if (selected.size > 2500000) {
        setThumbnailError('image size must be < 2.5 MB')
        return
      }
      setThumbnailError(null)
      const splitting = selected.name.split('.')
      // const profileType = splitting[1]

      const storageRef = ref(bucket, `/profilePic/${user.uid}`)
      const uploadTask = uploadBytesResumable(storageRef, selected)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          setProgress(prog)
        },
        (err) => {
          console.log(err)
        },
        async () => {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
          setThumbnailUrl(downloadUrl)
        }
      )

      setThumbnailError(null)
      setThumbnail(selected)
    },
    [setThumbnailUrl]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // Format phone number as user types
  const formatPhoneNumber = (value) => {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    if (phoneNumber.length < 4) return `(${phoneNumber}`
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  // Handle phone number input
  const handlePhoneInput = (e, onChange) => {
    let value = e.target.value
    // Allow backspace/delete to work properly
    if (value.length < e.target.selectionStart) {
      value = value.slice(0, -1)
    }
    // Only allow numbers and formatting characters
    if (!/^[()\d\s-]*$/.test(value)) return

    const formatted = formatPhoneNumber(value)
    onChange(formatted)
  }

  return (
    <ProfileInfoCard cardWrapped={cardWrapped} ref={refrence}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='inputs'>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  pb: 1,
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.text.primary
                      : 'rgba(0, 0, 0, 0.87)',
                }}
              >
                Fullname
              </FormLabel>
              <TextField
                variant='outlined'
                placeholder='Fullname'
                sx={{
                  borderRadius: 0,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#FFFFFF',
                    '& input': {
                      color: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.text.primary
                          : 'rgba(0, 0, 0, 0.87)',
                      '&::placeholder': {
                        color: (theme) =>
                          theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.5)'
                            : 'rgba(0, 0, 0, 0.6)',
                        opacity: 1,
                      },
                    },
                  },
                }}
                fullWidth
                size='small'
                error={!!errors.displayName}
                {...register('displayName', { required: true })}
              />
              <FormHelperText>{errors.displayName && 'This is required'}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  pb: 1,
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.text.primary
                      : 'rgba(0, 0, 0, 0.87)',
                }}
              >
                Phone
              </FormLabel>
              <Controller
                name='phone'
                control={control}
                rules={{
                  pattern: {
                    value: /^\(\d{3}\) \d{3}-\d{4}$/,
                    message: 'Please enter a valid phone number',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    variant='outlined'
                    placeholder='(___) ___-____'
                    sx={{
                      borderRadius: 0,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#FFFFFF',
                        '& input': {
                          color: (theme) =>
                            theme.palette.mode === 'dark'
                              ? theme.palette.text.primary
                              : 'rgba(0, 0, 0, 0.87)',
                          '&::placeholder': {
                            color: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.5)'
                                : 'rgba(0, 0, 0, 0.6)',
                            opacity: 1,
                          },
                        },
                      },
                    }}
                    fullWidth
                    size='small'
                    error={!!errors.phone}
                    value={value}
                    onChange={(e) => handlePhoneInput(e, onChange)}
                    inputProps={{
                      maxLength: 14,
                    }}
                  />
                )}
              />
              <FormHelperText>{errors.phone && errors.phone.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          {...getRootProps()}
          sx={(theme) => ({
            borderRadius: 0.6,
            padding: '1.5rem',
            minHeight: '100px',
            border: `1px dashed ${
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)'
            }`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            gap: '8px',
            width: '-webkit-fill-available',
            backgroundColor:
              theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#FFFFFF',
            '&:hover': {
              borderColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.main
                  : theme.palette.primary.main,
              backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
            },
          })}
        >
          <input type='file' {...getInputProps()} />
          {thumbnailError && (
            <Typography
              className='error'
              sx={(theme) => ({
                color: theme.palette.error.main,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.05)',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '0.875rem',
              })}
            >
              {thumbnailError}
            </Typography>
          )}
          {error && (
            <Typography
              className='error'
              sx={(theme) => ({
                color: theme.palette.error.main,
                backgroundColor:
                  theme.palette.mode === 'dark' ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.05)',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '0.875rem',
              })}
            >
              {error}
            </Typography>
          )}
          <UploadFileIcon
            sx={(theme) => ({
              fontSize: '20px',
              width: '1.5em',
              height: '1.5em',
              color:
                theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.87)',
            })}
          />
          <Typography
            sx={(theme) => ({
              fontSize: '17px',
              textAlign: 'center',
              color:
                theme.palette.mode === 'dark' ? theme.palette.text.primary : 'rgba(0, 0, 0, 0.87)',
            })}
          >
            Select an image file to upload
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: '14px',
              textAlign: 'center',
              color:
                theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'rgba(0, 0, 0, 0.6)',
            })}
          >
            Or drag and drop it here
          </Typography>
        </Box>
        <ProgressBox>
          <Typography>{progress}%</Typography>
          <LinearProgress variant='determinate' color='primary' value={progress} />
          <Button variant='contained' type='button' disabled={progress === 0 || progress === 100}>
            cancel
          </Button>
        </ProgressBox>
        <Button
          color='primary'
          variant='contained'
          type='submit'
          disabled={loading}
          sx={(theme) => ({
            textTransform: 'capitalize',
            padding: '5px 15px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : 'rgba(255, 255, 255, 0.2)',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.dark
                  : 'rgba(255, 255, 255, 0.3)',
            },
          })}
        >
          update profile
        </Button>
      </form>
    </ProfileInfoCard>
  )
}

export default Profile
