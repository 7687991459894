import { createTheme } from '@mui/material/styles';

export const getTheme = (isDarkMode) => {
  return createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#FCB325',
        light: isDarkMode ? '#fcd169' : '#fcc251',
        dark: isDarkMode ? '#c98b1d' : '#b47d1a',
      },
      background: {
        default: isDarkMode ? '#121212' : '#f8f9fa',
        paper: isDarkMode ? '#1e1e1e' : '#ffffff',
      },
      text: {
        primary: isDarkMode ? '#ffffff' : '#000000',
        secondary: isDarkMode ? '#b3b3b3' : '#666666',
      },
      navbar: {
        background: isDarkMode ? '#1a1a1a' : '#ffffff',
        text: isDarkMode ? '#ffffff' : '#170F49',
        border: isDarkMode ? '#333333' : '#dff2ff',
      },
      footer: {
        background: isDarkMode ? '#1a1a1a' : '#f2f9fe',
        border: isDarkMode ? '#333333' : '#dff2ff',
        text: isDarkMode ? '#ffffff' : '#000000',
      },
      landing: {
        background: isDarkMode ? '#121212' : '#f8f9fa',
        paper: isDarkMode ? '#1e1e1e' : '#ffffff',
        cardBg: isDarkMode ? '#242424' : '#ffffff',
        border: isDarkMode ? '#333333' : '#dff2ff',
        shadow: isDarkMode 
          ? '0px 4px 28px rgba(0, 0, 0, 0.4)' 
          : '0px 4px 28px rgba(2, 2, 2, 0.1)',
      },
      pricing: {
        background: isDarkMode ? '#242424' : '#ffffff',
        headerText: isDarkMode ? '#ffffff' : '#170F49',
        priceText: isDarkMode ? '#ffffff' : '#000000',
        featureText: isDarkMode ? '#e0e0e0' : '#666666',
        border: isDarkMode ? '#333333' : '#dff2ff',
        shadow: isDarkMode 
          ? '0px 4px 28px rgba(0, 0, 0, 0.4)' 
          : '0px 4px 28px rgba(2, 2, 2, 0.1)',
        tag: {
          background: isDarkMode ? '#333333' : '#fff3d8',
          text: isDarkMode ? '#FCB325' : '#000000',
        }
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
            borderBottom: `1px solid ${isDarkMode ? '#333333' : '#dff2ff'}`,
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#242424' : '#ffffff',
            borderColor: isDarkMode ? '#333333' : '#dff2ff',
            '& .MuiTypography-root': {
              color: isDarkMode ? '#ffffff' : 'inherit',
            },
            '& .plan-name': {
              color: isDarkMode ? '#FCB325' : '#4A3AFF',
            },
            '& .plan-price': {
              color: isDarkMode ? '#ffffff' : '#000000',
            },
            '& .plan-feature': {
              color: isDarkMode ? '#e0e0e0' : '#666666',
            },
            '& .monthly-tag': {
              backgroundColor: isDarkMode ? '#333333' : '#fff3d8',
              color: isDarkMode ? '#FCB325' : '#000000',
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
          }
        }
      }
    }
  });
}; 