import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/landing/Landing";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/settings/Settings";
import NotFound from "./components/NotFound";
import Navbar from "./components/Navigation/Navbar";
import Reset from "./pages/reset/Reset";
import Team from "./pages/team/InviteTeam";
import Affiliate from "./pages/affiliate/Affiliate";
import ProjectDashboard from "./pages/projects/ProjectDashboard/ProjectDashboard";
import ProjectFormNew from "./pages/projects/ProjectForm/ProjectForm";
import ProjectDetails from "./pages/projects/ProjectDetails/ProjectDetails";
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from './context/ThemeContext';
import { getTheme } from './theme/theme';
import { useTheme } from './context/ThemeContext';
import Footer from "./components/Footer";
import { styled } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
import ConfirmProjectInvite from "./pages/team/ConfirmProjectInvite";
import ProtectedRoutes from "./ProtectedRoutes";
import Testimonials from "./components/Testimonials/Testimonials";
import { Close as IconClose } from "@mui/icons-material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useAuthContext } from "./hooks/useAuthContext";
import { useEffect, useState } from "react";
import { getCustomClaimRole } from "./firebase";
import UpgradePage from "./pages/Upgrade/upgrade";
import AcceptInvitation from "./pages/team/components/AcceptInvitation";
import Feature from "./pages/features/feature";
import About from "./pages/about/About";
import ContactUs from "./pages/contactus/ContactUs";
import Blog from "./pages/blog/Blog";
import GettingStarted from "./pages/gettingstarted/GettingStarted";
import HelpCenter from "./pages/helpcenter/HelpCenter";
import TermsAndConditions from "./pages/termsandcondition/TermsAndCondition";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import AffiliateInfo from "./pages/affiliatepublic/AffiliatePublic";
import CustomAuth from "./pages/CustomAuth/CustomAuth";

const AppContainer = styled(Box)(() => ({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "flex-start",
  overflowX: "hidden",
  backgroundColor: "#F9FAFC",

  "& > .content": {
    flex: 1,
    padding: "20px 0px",
    position: "relative",
    minHeight: "calc(96vh - 70px)",
    backgroundColor: "#F9FAFC",
    display: "flex",
    // alignItems: 'center',
  },
}));

function SnackbarCloseButton({ id }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton style={{ color: "white" }} onClick={() => closeSnackbar(id)}>
      <IconClose />
    </IconButton>
  );
}

const ThemedApp = ({ userRole, setUserRole, stripeLoader, authIsReady, setStripeLoader }) => {
  const { isDarkMode } = useTheme();
  const theme = getTheme(isDarkMode);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <AppContainer>
        <SnackbarProvider
          action={(key) => <SnackbarCloseButton id={key} />}
          hideIconVariant
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <BrowserRouter>
            {window.location.pathname === "/custom-auth/action" ? null : (
              <Navbar userRole={userRole} setUserRole={setUserRole} />
            )}
            <Box className="content">
              <Routes>
                <Route path="/" element={<Landing userRole={userRole} />} />
                <Route element={<ProtectedRoutes loginRoutes />}>
                  <Route
                    path="/register"
                    element={<Register setStripeLoader={setStripeLoader} />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/password-reset" element={<Reset />} />
                  <Route path="/features" element={<Feature />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/custom-auth/action" element={<CustomAuth />} />
                </Route>

                <Route
                  element={
                    <ProtectedRoutes
                      dashbaordRoutes
                      stripeLoader={stripeLoader}
                    />
                  }
                >
                  <Route
                    path="/dashboard"
                    element={<Dashboard userRole={userRole} />}
                  />
                  <Route
                    path="/projects"
                    element={<ProjectDashboard userRole={userRole} />}
                  />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/affiliate" element={<Affiliate />} />
                  <Route path="/add-project" element={<ProjectFormNew />} />
                  <Route
                    path="/update-project/:id"
                    element={<ProjectFormNew />}
                  />
                  <Route
                    path="/myteam/:uid"
                    element={<Team userRole={userRole} />}
                  />
                  {/* <Route path='/invite/:id' element={<Team userRole={userRole} />} /> */}
                  <Route
                    path="/upgrade"
                    element={<UpgradePage userRole={userRole} />}
                  />
                  <Route path="/projects/:id" element={<ProjectDetails />} />
                  <Route
                    path="/teamInvite/:inviterId/:inviteeEmail"
                    element={<AcceptInvitation />}
                  />
                  <Route
                    path="/projectInvite/:inviterId/:inviteeId"
                    element={<ConfirmProjectInvite />}
                  />
                </Route>
                <Route path="/getting-started" element={<GettingStarted />} />
                <Route path="/help-center" element={<HelpCenter />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/affiliate-info" element={<AffiliateInfo />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/notfound" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
            {!authIsReady && <Testimonials />}
            {window.location.pathname === "/custom-auth/action" ? null : (
              <Footer />
            )}
          </BrowserRouter>
        </SnackbarProvider>
      </AppContainer>
    </MUIThemeProvider>
  );
};

const App = () => {
  const { user, authIsReady } = useAuthContext();
  const [userRole, setUserRole] = useState(undefined);
  const [stripeLoader, setStripeLoader] = useState(false);

  useEffect(() => {
    (async (_) => {
      if (user) {
        let role = await getCustomClaimRole();
        setUserRole(role);
      }
    })();
  }, [user]);

  return (
    <ThemeProvider>
      <ThemedApp 
        userRole={userRole}
        setUserRole={setUserRole}
        stripeLoader={stripeLoader}
        authIsReady={authIsReady}
        setStripeLoader={setStripeLoader}
      />
    </ThemeProvider>
  );
};

export default App;
