import { useCollection } from '../../hooks/useCollection'
import { useAuthContext } from '../../hooks/useAuthContext'
import { Link } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Container, Typography } from '@mui/material'
import { getCustomClaimRole } from '../../firebase'
import { useTeamOwnersContext } from '../../hooks/useTeamOwnersContext'
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useSnackbar } from 'notistack'
import WelcomeBox from './components/WelcomeBox'
import CircularLoader from '../../components/CircularLoader/CircularLoader'

const MainContainer = styled(Box)(({ theme }) => ({
  padding: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1rem',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: 8,
    '& input': {
      padding: '11px 8px',
    },
  },
}))

const Dashboard = () => {
  const { user } = useAuthContext()
  const { isTeamMember } = useTeamOwnersContext()
  const { enqueueSnackbar } = useSnackbar()
  const [projectsExists, setProjectExists] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userRole, setUserRole] = useState('')
  const [roleLoading, setRoleLoading] = useState(true)

  useEffect(() => {
    ;(async (_) => {
      let role = await getCustomClaimRole()
      setUserRole(role)
      setRoleLoading(false)
      const projCol = collection(db, 'projects')
      const q1 = query(projCol, where('uid', '==', user.uid))
      const unsub = onSnapshot(
        q1,
        async (snap) => {
          setProjectExists(snap.docs.length > 0)
          setLoading(false)
        },
        (error) => {
          enqueueSnackbar(error.message, { variant: 'error' })
          setLoading(false)
          setRoleLoading(false)
        }
      )
      return () => unsub()
    })()
  }, [user])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  if (loading || roleLoading) {
    return <CircularLoader />
  }

  return (
    <Box sx={{ bgcolor: '#F8FAFC', width: '100%' }}>
      <MainContainer>
        <Box sx={{ display: 'flex', minHeight: '60vh', alignItems: { sm: 'start' } }}>
          <WelcomeBox projectsExists={projectsExists} />
        </Box>
      </MainContainer>
    </Box>
  )
}

export default Dashboard
