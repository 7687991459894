import { Grid, Box, Typography, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import CM_Name_Logo from '../images/CM_Logo_With_name.png'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Container } from '@mui/system'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ThemeToggle from './ThemeToggle'

const StyledFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  background: theme.palette.footer.background,
  minHeight: '450px',
  maxHeight: 'auto',
  width: '100%',
  marginTop: 'auto',
  padding: '4rem 4rem 2rem',
  borderTop: `1px solid ${theme.palette.footer.border}`,
  color: theme.palette.footer.text,

  [theme.breakpoints.down('md')]: {
    padding: '3rem 3rem 1rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1.5rem 1rem',
  },

  '& .MuiGrid-container': {
    maxWidth: '1250px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },

  '& .mediaBox': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    '& > .logo': {
      position: 'relative',
      width: '200px',
      height: '110px',
      [theme.breakpoints.down('sm')]: {
        width: '160px',
        height: '90px',
      },
      '&> img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
    },
    '& > .MuiTypography-root': {
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '30px',
      maxWidth: '310px',
    },
    '& > .iconsList': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      '& > svg': {
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        padding: '5px',
        color: theme.palette.primary.light,
        transition: 'all 0.4s ease',
        '&:first-of-type': {
          paddingLeft: '0px',
          marginLeft: '-5px',
        },
        '&:hover': {
          transform: 'scale(1.2)',
        },
      },
    },
  },

  '& .links': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '2.5rem 1rem',
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0rem',
    },
    '& > .lists': {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '180px',
      maxWidth: '200px',
      gap: '12px',
      [theme.breakpoints.down('md')]: {
        minWidth: '160px',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '130px',
      },
      '& > .name': {
        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : '#000',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
        },
      },
      '& > .MuiBox-root': {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        '& > .MuiTypography-root': {
          fontSize: '18px',
          lineHeight: '20px',
          textTransform: 'capitalize',
          [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
          },
        },
      },
    },
  },

  '& .bottom': {
    display: 'flex',
    alignItems: 'center',
    // gap: "1rem",
    '& .MuiTypography-root': {
      textAlign: 'left',
      fontSize: '15px',
      '&.privacy': {
        textAlign: 'center',
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '160px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        textAlign: 'center',
        '&:first-of-type': {
          marginBottom: '1.2rem',
        },
      },
    },
  },

  '& .MuiTypography-root': {
    color: theme.palette.footer.text,
  },

  '& .iconsList svg': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
  },

  '& a': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  '& .MuiDivider-root': {
    borderColor: theme.palette.footer.border,
  },
}))

const footerLinks = [
  {
    name: 'product',
    links: [
      // { title: "features", to: "/features" },
      { title: 'pricing', to: '#pricing' },
      { title: 'reviews', to: '#testimonials' },
      // { title: "updates" },
    ],
  },
  {
    name: 'Company',
    links: [
      { title: 'About', to: '#about' },
      { title: 'Contact Us', to: '/contact-us' },
      { title: 'Blog', to: '/blog' },
      // { title: "Affiliate", to: "/affiliate-info" },
    ],
  },
  {
    name: 'Support',
    links: [
      { title: 'Getting started', to: '/getting-started' },
      { title: 'Help center', to: '/help-center' },
      // { title: "Server status" },
      // { title: "Report a bug" },
      // { title: "Chat support" },
    ],
  },
]

export const scrollToOtherPageElement = (id, allowMultiple, navBarHeight) => {
  const sectionId = id.split('#')[1]
  const section = document.querySelector(`#${sectionId}`)
  const notLoopIds = ['sales', 'getting-started']
  navBarHeight = navBarHeight || 64

  for (let i = 0; i <= 2; i++) {
    if (i === 0) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        offset: -80,
      })
    } else if (!notLoopIds.includes(sectionId) || allowMultiple) {
      setTimeout(
        () => {
          // section.scrollIntoView({
          //   behavior: "auto",
          //   block: "start",
          //   inline: "start",
          // });
          window.scrollTo(0, section.getBoundingClientRect().top + window.scrollY - navBarHeight)
        },
        i === 1 ? i * 800 : i * 800 - 400
      )
    }
  }
}
const Footer = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const getCopyrightYear = () => {
    const currentYear = new Date().getFullYear()
    return `Copyright © ${currentYear}`
  }

  return (
    <StyledFooter id='contact'>
      <Grid container spacing={5} display='flex' justifyContent='center'>
        <Grid item xs={12} sm={5} md={4}>
          <Box className='mediaBox'>
            <Box className='logo'>
              <img src={CM_Name_Logo} alt='CM_Name_Logo' />
            </Box>
            <Typography>
              Secure credential management platform for teams and individuals. Organize, share, and
              protect your project credentials with ease.
            </Typography>
            <Box className='iconsList'>
              <ThemeToggle />
              <FacebookIcon />
              <TwitterIcon />
              <InstagramIcon />
              <LinkedInIcon />
              <YouTubeIcon />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={8} sx={{ minWidth: '380px' }}>
          <Box className='links'>
            {footerLinks.map(({ links, name }, i) => (
              <Box key={i} className='lists'>
                <Typography variant='h6' className='name'>
                  {name}
                </Typography>
                <Box>
                  {links.map((link, idx) => (
                    <Typography
                      key={idx + link.title}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (link?.to.includes('#')) {
                          location.pathname !== '/' && navigate('/')
                          return setTimeout(() => {
                            scrollToOtherPageElement(link?.to)
                          }, 100)
                        }
                        navigate(link?.to)
                      }}
                    >
                      {link.title}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item container xs={12} mt={5} className='bottom'>
          <Grid item xs={12}>
            <Divider variant='fullWidth' sx={{ mb: 2, borderColor: '#eee4fa' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              {getCopyrightYear()}{' '}
              <a
                href='https://codeshock.dev'
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                Code Shock
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className='privacy'>
              <span style={{ fontWeight: 'normal' }}>All Rights Reserved |</span>{' '}
              <Link to='/terms-and-conditions'>Terms and Conditions</Link> |{' '}
              <Link to='/privacy-policy'>Privacy Policy</Link>
              {/* <Typograp>Privacy Policy</Typograp> */}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
