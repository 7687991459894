import React, { useEffect } from 'react'
import {
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  Box,
  Stack,
  IconButton,
} from '@mui/material'
import { styled, keyframes } from '@mui/material/styles'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import whyJoin1 from '../../images/whyJoin1.png'
import whyJoin2 from '../../images/whyJoin2.png'
import whyJoin3 from '../../images/whyJoin3.png'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

const slideInLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  70% {
    transform: translateX(10px);
    opacity: 1;
  }
  85% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const slideInRightFirst = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  70% {
    transform: translateX(-10px);
    opacity: 1;
  }
  85% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const slideInRightSecond = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  70% {
    transform: translateX(-10px);
    opacity: 1;
  }
  85% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const MuiGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}))

const WhyJoinBox = styled(Box)(({ theme }) => ({
  padding: '5rem 4rem',
  [theme.breakpoints.down('md')]: {
    padding: '4rem 2rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 0.8rem',
  },
  background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
  '& .MuiGrid-item.leftGrid': {
    '& .left_stack': {
      width: 'fit-content',
      '& ul': {
        '& li': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          '& > svg': {
            color: theme.palette.primary.main,
          },
          '& >.MuiTypography-root': {
            flex: 1,
            fontWeight: 'bold',
            color:
              theme.palette.mode === 'dark' ? theme.palette.text.primary : 'rgba(0, 0, 0, 0.8)',
          },
        },
      },
    },
  },

  '& .MuiGrid-item.rightGrid': {
    '& .MuiBox-root:not(.btn)': {
      position: 'relative',
      borderRadius: '3px',
      overflow: 'hidden',
      '& > img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .slide-left': {
      animation: `${slideInLeft} 1.2s cubic-bezier(0.34, 1.56, 0.64, 1) forwards`,
    },
    '& .slide-right-first': {
      animation: `${slideInRightFirst} 1.2s cubic-bezier(0.34, 1.56, 0.64, 1) 0.3s forwards`,
      opacity: 0,
    },
    '& .slide-right-second': {
      animation: `${slideInRightSecond} 1.2s cubic-bezier(0.34, 1.56, 0.64, 1) 0.6s forwards`,
      opacity: 0,
    },
  },
}))

const WhyJoin = () => {
  return (
    <WhyJoinBox id='about'>
      <Container
        sx={{
          py: 2,
        }}
      >
        <MuiGrid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              display: 'flex',
              justifyContent: { sm: 'center', md: 'flex-start' },
            }}
            className='leftGrid'
          >
            <Stack spacing={{ xs: 1.5, sm: 2.5, md: 3 }} className='left_stack'>
              <Typography color='primary' variant='h6'>
                What's Cred Management?
              </Typography>
              <Typography
                variant='h3'
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
                  maxWidth: 450,
                }}
              >
                Why Join the Cred Management Network?
              </Typography>
              <Typography
                variant='body1'
                maxWidth={550}
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                }}
              >
                Cred Management is your secure solution for managing team credentials and access
                control. We provide enterprise-grade security with seamless collaboration features
                designed for modern development teams.
              </Typography>
              <Stack component='ul' spacing={2}>
                <Box component='li'>
                  <RadioButtonCheckedIcon />
                  <Typography
                    variant='body1'
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    Secure Credential Sharing
                  </Typography>
                </Box>
                <Box component='li'>
                  <RadioButtonCheckedIcon />
                  <Typography
                    variant='body1'
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    Role-Based Access Control
                  </Typography>
                </Box>
                <Box component='li'>
                  <RadioButtonCheckedIcon />
                  <Typography
                    variant='body1'
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    Audit Logs & Monitoring
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            gap={{ xs: 2, sm: 3 }}
            className='rightGrid'
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: '0.8rem', sm: '1rem' },
              justifyContent: { sm: 'center', md: 'flex-end' },
              alignItems: 'center',
            }}
          >
            <Box
              className='slide-left'
              sx={{
                width: { xs: '300px', sm: '264px' },
                height: { xs: '330px', sm: '370px' },
              }}
            >
              <img src={whyJoin1} style={{ zIndex: 1 }} alt='why join' />
              <Box
                className='btn'
                sx={{
                  position: 'absolute',
                  top: '42%',
                  left: '38%',
                  right: '0%',
                  zIndex: 3,
                }}
              >
                <IconButton color='secondary' size='large'>
                  <PlayCircleIcon sx={{ fontSize: '2.75rem' }} />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: { xs: '0.8rem', sm: '1rem' },
              }}
            >
              <Box
                className='slide-right-first'
                sx={{
                  width: { xs: '300px', sm: '260px' },
                  height: { xs: '300px', sm: '260px' },
                }}
              >
                <img src={whyJoin3} />
              </Box>
              <Box
                className='slide-right-second'
                sx={{
                  width: { xs: '300px', sm: '260px' },
                  height: { xs: '300px', sm: '260px' },
                }}
              >
                <img src={whyJoin2} />
              </Box>
            </Box>
          </Grid>
        </MuiGrid>
      </Container>
    </WhyJoinBox>
  )
}

export default WhyJoin
