import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { styled } from '@mui/material/styles'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import carouselImg1 from '../../images/carousel1.png'

const CarouselContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '-18px',

  '& .carousel-root': {
    transition: 'all 0.4s ease',
    '& .MuiButtonBase-root': {
      background: 'hsl(0deg 0% 0% / 40%)',
      transition: 'all 0.4s ease',
      opacity: '0',
    },
    '&:hover': {
      '& .MuiButtonBase-root': {
        opacity: '1',
        // background: "hsl(0deg 0% 0% / 30%)",
      },
    },
    'li.slide > div': {
      height: '550px',
      position: 'relative',
      '& > img': {
        height: '100%',
        filter: 'brightness(0.65)',
      },
      [theme.breakpoints.down('md')]: {
        height: '360px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '260px',
      },
      '& > .textStack': {
        height: '100%',
        width: '100%',
        padding: '1.5rem',
        position: 'absolute',
        top: '0%',
        bottom: '0%',
        '& > .MuiTypography-root': {
          textShadow: '2px 2px 4px rgba(0,0,0,0.9)',
        },
      },
    },
  },
}))

const CustomArrow = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'next',
})(({ next }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  background: 'hsl(0deg 0% 0% / 26%)',
  zIndex: 1,
  height: 'fit-content',
  marginTop: 'auto',
  marginBottom: 'auto',
  padding: '10px',
  color: 'rgb(247 247 248 / 74%)',
  transition: 'all 0.4s ease',
  '&:hover': {
    background: 'hsl(0deg 0% 0% / 26%)',
  },
  '&.prev': {
    left: 0,
    marginLeft: '10px',
  },
  '&.next': {
    right: 0,
    marginRight: '10px',
  },
}))

const ImageCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const carouselData = [
    {
      src: carouselImg1,
      title: 'Secure Team Credential Management',
      desc: "Protect and manage your team's access credentials with enterprise-grade security and seamless sharing.",
    },
    {
      src: 'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      title: 'Streamline Project Access Control',
      desc: 'Instantly grant and revoke access, track usage, and maintain security with our powerful credential management platform.',
    },
    {
      src: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      title: 'Built for Modern Development Teams',
      desc: 'Keep your development credentials organized and secure with role-based permissions and detailed audit logs.',
    },
    {
      src: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      title: 'Scale with Confidence',
      desc: 'From startups to enterprises, our platform grows with your team while maintaining top-tier security and control.',
    },
  ]

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index)
    }
  }

  return (
    <CarouselContainer>
      <Carousel
        showThumbs={false}
        infiniteLoop
        autoPlay
        interval={10000}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <CustomArrow onClick={onClickHandler} title={label} className='prev' size='small'>
              <ArrowBack />
            </CustomArrow>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <CustomArrow onClick={onClickHandler} title={label} className='next' size='small'>
              <ArrowForward />
            </CustomArrow>
          )
        }
      >
        {carouselData.map((obj, idx) => (
          <Box key={idx}>
            <img src={obj.src} alt='Team Mangment Meeting Pic' />
            <Stack className='textStack' spacing={2} justifyContent='center' alignItems='center'>
              <Typography
                variant='h2'
                color='#fff'
                maxWidth={700}
                sx={{
                  fontSize: { xs: 30, sm: 38, md: 44, lg: 48 },
                  lineHeight: { xs: '32px', sm: '40px', md: '50px' },
                  fontWeight: 700,
                  letterSpacing: '0.5px',
                }}
              >
                {obj.title}
              </Typography>
              <Typography
                variant='h5'
                color='#fff'
                maxWidth={750}
                px={2}
                fontWeight='500'
                lineHeight='25px'
                sx={{
                  fontSize: { xs: 14, sm: 18, md: 22 },
                  lineHeight: { xs: '20px', sm: '22px', md: '25px' },
                  letterSpacing: '0.3px',
                }}
              >
                {obj.desc}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Carousel>
    </CarouselContainer>
  )
}

export default ImageCarousel
