import {
  Grid,
  ToggleButtonGroup,
  Box,
  ToggleButton,
  Typography,
  CircularProgress,
  Button,
  Card,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPlans, subscribeToPlan } from '../../firebase'
import { auth } from '../../firebase/config'
import { styled } from '@mui/material/styles'

const PricingCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.pricing.background,
  color: theme.palette.pricing.headerText,
  boxShadow: theme.palette.pricing.shadow,
  border: `1px solid ${theme.palette.pricing.border}`,

  '& .plan-name': {
    color: theme.palette.mode === 'dark' ? theme.palette.primary.light : '#4A3AFF',
  },

  '& .plan-price': {
    color: theme.palette.pricing.priceText,
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: 1.2,
    margin: '16px 0',
    wordBreak: 'break-word',

    '& .period': {
      fontSize: '1.25rem',
      opacity: 0.8,
    },
  },

  '& .plan-feature': {
    color: theme.palette.pricing.featureText,
  },

  '& .monthly-tag': {
    backgroundColor: theme.palette.pricing.tag.background,
    color: theme.palette.pricing.tag.text,
    padding: '4px 8px',
    borderRadius: '4px',
  },

  '& .MuiButton-root': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : undefined,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : undefined,
    },
  },
}))

function NewPricingCard({ cardsToShow, userRole }) {
  const navigate = useNavigate()
  const [plans, setPlans] = useState([])
  const [loaders, setLoaders] = useState([])
  const [alignment, setAlignment] = useState('monthly')

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment || 'monthly')
  }

  useEffect(() => {
    ;(async (_) => {
      setPlans(await getPlans())
    })()
  }, [])
  console.log(plans, ' plans')
  useEffect(() => {
    if (!!plans?.length) {
      setLoaders(Array(plans.length).fill(false))
    }
  }, [plans])

  const handleSubscribePlan = async (priceDocId, ind) => {
    let loadersClone = [...loaders]
    loadersClone[ind] = true
    setLoaders(loadersClone)
    await subscribeToPlan(priceDocId)
  }
  return (
    <>
      {/* <Typography variant="h3">Create Your Own Team</Typography> */}
      {plans?.length > 0 ? (
        (cardsToShow === 1
          ? [plans.find((v) => v.docId === 'prod_MiXURiirNat4Z2')]
          : plans.filter((v) => ['prod_MiXUYJLtTI7tPG', 'prod_MiXURiirNat4Z2'].includes(v.docId))
        ).map((plan, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            justifyContent='center'
            alignItems={index === 1 ? 'baseline' : 'flex-start'}
            display={'flex'}
          >
            <PricingCard
              sx={{
                '& div>div>.MuiTypography-root.MuiTypography-body2': {
                  textAlign: 'start',
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '570px',
                width: { xs: '100%', md: '82%' },
                textAlign: 'center',
                py: 4,
                px: { xs: 2, sm: 3, md: 4 },
                borderRadius: '16px',
                '& > div': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  flex: 1,
                },
              }}
            >
              <div>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <img src='/icons/Featured icon.png' alt='' width={40} height={40} />
                </Box>
                {plan.prices?.length > 1 && (
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    style={{ alignSelf: 'center', marginBottom: 20 }}
                  >
                    <ToggleButton value='monthly'>Monthly</ToggleButton>
                    <ToggleButton value='yearly'>Yearly</ToggleButton>
                  </ToggleButtonGroup>
                )}
                <Typography
                  variant='h5'
                  className='plan-name'
                  sx={{
                    fontWeight: '600',
                    py: 1,
                  }}
                >
                  Team Plan
                </Typography>
                <Typography className='plan-price'>
                  {!plan.prices?.length ? (
                    'Free'
                  ) : (
                    <>
                      $
                      {Math.round(
                        plan.prices[alignment === 'monthly' ? 0 : 1]?.unit_amount / 100
                      ).toFixed(2)}
                      <span className='period'>/{alignment === 'monthly' ? 'mth' : 'year'}</span>
                    </>
                  )}
                </Typography>
                <Typography variant='body2' py={1}>
                  {!plan.prices?.length
                    ? ''
                    : `Billed ${plan.prices[alignment === 'monthly' ? 0 : 1]?.interval}ly`}
                </Typography>
                {Object.values(plan.metadata)
                  .slice(0, Object.values(plan.metadata).length - 1)
                  .map((feature, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          pt: index === 0 ? 4 : 0,
                          mb: index === Object.values(plan.metadata).length - 1 ? 5 : 2,
                          display: 'flex',
                        }}
                      >
                        <img src='/icons/Check icon.png' alt='' width={24} height={24} />
                        <Typography variant='body2' pl={2}>
                          {feature}
                        </Typography>
                      </Box>
                    )
                  })}
              </div>
              {loaders[index] ? (
                <CircularProgress style={{ alignSelf: 'center', marginRight: '10%' }} />
              ) : (
                <Button
                  disabled={(loaders.includes(true) && !loaders[index]) || userRole === 'team'}
                  onClick={(_) =>
                    auth.currentUser && !!plan.prices?.length
                      ? handleSubscribePlan(
                          plan.prices[alignment === 'monthly' ? 0 : 1]?.docId,
                          index
                        )
                      : navigate(`/register`, { state: true })
                  }
                  variant='contained'
                  sx={{
                    py: '14px!important',
                    fontWeight: 700,
                    fontSize: 16,
                    mr: 3,
                  }}
                >
                  {userRole === 'team' ? 'Already Paid' : 'Get Started'}
                </Button>
              )}
            </PricingCard>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} sm={6} md={4} justifyContent='center' display={'flex'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '570px',
              bgcolor: '#FFFFFF',
              width: { xs: '100%', md: '82%' },
              textAlign: 'center',
              py: 4,
              pl: 5,
              pr: 2,
              borderRadius: '16px',
              boxShadow:
                '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
              border: '1px solid #EAECF0',
            }}
          >
            <CircularProgress size={100} />
          </Box>
        </Grid>
      )}
    </>
  )
}

export default NewPricingCard
